import React from 'react';
import dataHooks from '../../data-hooks';
import { limitationToString } from './variationLimitationUtils';
import { VariationLimitation } from '@wix/restaurants-client-logic';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';

export interface VariationLimitationViewProps {
  limitation: VariationLimitation;
  min?: number;
  max?: number;
  className?: string;
  t: TranslationFunction;
}

const VariationLimitationView: React.FC<VariationLimitationViewProps> = ({ limitation, min, max, className, t }) => {
  const str = limitationToString(t, limitation, min, max);
  return str ? (
    <div className={className} data-hook={dataHooks.dishModalVariationLimitation}>
      {str}
    </div>
  ) : null;
};

VariationLimitationView.displayName = 'VariationLimitationView';

export default translate()(VariationLimitationView);

import React from 'react';
import styles from './CartLoyaltyButton.scss';
import { TEXT_BUTTON_PRIORITY, TextButton } from '../TextButton';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';

export interface CartLoyaltyButtonProps {
  t: TranslationFunction;
  onClick: () => void;
  disabled?: boolean;
}

const CartLoyaltyButton: React.FC<CartLoyaltyButtonProps> = ({ onClick, t, disabled }) => {
  return (
    <div className={styles.buttonWrapper}>
      <TextButton
        disabled={disabled}
        priority={TEXT_BUTTON_PRIORITY.primary}
        onClick={onClick}
        data-hook={dataHooks.cartAddLoyalty}
        className={styles.fullWidthButton}
      >
        <Text typography="p2-m-colorless">{t('cart_promo_loyalty_redeem_option_CTA')}</Text>
      </TextButton>
    </div>
  );
};

CartLoyaltyButton.displayName = 'CartLoyaltyButton';

export default translate()(CartLoyaltyButton);

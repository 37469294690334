
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/58329b27688fd29c/packages/restaurants-olo-client/src/components/MainPage/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/work/58329b27688fd29c/packages/restaurants-olo-client/src/components/MainPage/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://75d712d6872d47f8a228763aab4e61ad@sentry.wixpress.com/428',
      id: '75d712d6872d47f8a228763aab4e61ad',
      projectName: 'restaurants-olo-client',
      teamName: 'restaurants',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'MainPage'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };

import React from 'react';
import { ToggleSwitch as TpaToggleSwitch, ToggleSwitchProps as TpaToggleSwitchProps } from 'wix-ui-tpa/ToggleSwitch';
import { st, classes } from './ToggleSwitch.st.css';

export interface ToggleSwitchProps extends TpaToggleSwitchProps {}

const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {
  return <TpaToggleSwitch {...props} className={st(classes.root, {}, '')} />;
};

ToggleSwitch.displayName = 'ToggleSwitch';

export default ToggleSwitch;

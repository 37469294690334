import { StyleParamType, createStylesParams } from 'yoshi-flow-editor-runtime/tpa-settings';

export type IStylesParamsMobileDesign = {
  menuTitleSize: StyleParamType.Number;
  menuDescriptionSize: StyleParamType.Number;
  sectionTabSize: StyleParamType.Number;
  sectionTitleSize: StyleParamType.Number;
  sectionDescriptionSize: StyleParamType.Number;
  dishTitleSize: StyleParamType.Number;
  dishDescriptionSize: StyleParamType.Number;
};

export const stylesParamsMobileDesign = createStylesParams<IStylesParamsMobileDesign>({
  menuTitleSize: {
    key: 'menu-title-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  menuDescriptionSize: {
    key: 'menu-description-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  sectionTabSize: {
    key: 'section-tab-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  sectionTitleSize: {
    key: 'section-title-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  sectionDescriptionSize: {
    key: 'section-description-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  dishTitleSize: {
    key: 'dish-title-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  dishDescriptionSize: {
    key: 'dish-description-size',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
});

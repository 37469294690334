import React from 'react';
import styles from './CartLoyaltyGuest.scss';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import DiscountIcon from '../DiscountIcon';
import { TranslationFunction } from 'i18next';
import { translate, useBi } from 'yoshi-flow-editor-runtime';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/SectionNotification';
import { TextButton } from '../TextButton';
import { calcEarnedPoints } from '@wix/restaurants-client-logic';
import classNames from 'classnames';
import CartDiscountIconWrapper from '../CartDiscount/CartDiscountIconWrapper';
import DiscountIconV2 from '../DiscountIcon/DiscountIconV2';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/src/types';

export interface CartLoyaltyGuestProps {
  t: TranslationFunction;
  iconUrl?: string;
  customPointsName?: string;
  loginMember: any;
  centsAmount: number;
  pointsRequired: number;
  currencyValue: number;
  useNewIcon?: boolean;
  earningRules: EarningRule[];
}

const CartLoyaltyGuest: React.FC<CartLoyaltyGuestProps> = ({
  t,
  iconUrl,
  customPointsName,
  loginMember,
  centsAmount,
  useNewIcon,
  earningRules,
}) => {
  const ref = React.useRef<HTMLSpanElement>(null);

  const biLogger = useBi();
  const pointToBeEarned = calcEarnedPoints({ rules: earningRules, moneyAmountInCents: centsAmount });

  return (
    <div
      className={classNames([styles.wrapper, useNewIcon && styles.newIcon])}
      data-hook={dataHooks.cartLoyaltyNonMemberWrapper}
    >
      {iconUrl ? (
        <CartDiscountIconWrapper
          iconUrl={iconUrl}
          artboardSize={useNewIcon ? 30 : 40}
          iconSize={useNewIcon ? 21 : 40}
        />
      ) : useNewIcon ? (
        <DiscountIconV2 />
      ) : (
        <DiscountIcon />
      )}
      <div className={styles.innerWrapper}>
        <div className={styles.content}>
          <div className={styles.textContent}>
            <Text typography="p2-m" className={styles.title} data-hook={dataHooks.loyaltyTitleNonMember}>
              <span tabIndex={-1} ref={ref}>
                {customPointsName
                  ? t('cart_promo_loyalty_banner_earnpoints_text', {
                      number: pointToBeEarned,
                      pointname: customPointsName,
                    })
                  : t('cart_promo_loyalty_banner_earnpoints_default_name_text', {
                      number: pointToBeEarned,
                    })}
              </span>
            </Text>
            <Text
              data-hook={dataHooks.loyaltyDescriptionNonMember}
              typography="p2-s"
              className={styles.pointsSelectionLabel}
            >
              {t('cart_promo_loyalty_banner_login_text', { pointname: customPointsName })}
            </Text>
            <TextButton
              data-hook={dataHooks.cartLoyaltyLogin}
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={() => {
                loginMember();
                biLogger.logInRequest({ origin: 'cart-loyalty-guest' });
              }}
              className={styles.button}
            >
              <Text typography="p2-s-colorless">{t('cart_promo_loyalty_banner_login_cta')}</Text>
            </TextButton>
          </div>
        </div>
      </div>
    </div>
  );
};

CartLoyaltyGuest.displayName = 'CartLoyaltyGuest';

export default translate()(CartLoyaltyGuest);

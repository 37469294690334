import React from 'react';
import Text from '../../core-components/Text';
import styles from './MenuViewEmptyState.scss';
import dataHooks from '../../data-hooks';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';

export interface MenuViewEmptyStateProps {
  id?: string;
  t: TranslationFunction;
}

const MenuViewEmptyState: React.FC<MenuViewEmptyStateProps> = ({ id, t }) => {
  return (
    <div
      className={styles.wrapper}
      data-hook={!id ? dataHooks.menuViewEmptyState : dataHooks.menuSectionEmptyState(id)}
    >
      <Text typography="p2-m">{t('online_ordering_menuitem_emptystate_text')}</Text>
    </div>
  );
};

MenuViewEmptyState.displayName = 'MenuViewEmptyState';

export default translate()(MenuViewEmptyState);

import React from 'react';
import { TextField as TpaTextField, TextFieldProps as TpaTextFieldProps } from 'wix-ui-tpa/TextField';
import { st, classes } from './TextField.st.css';

export interface TextFieldProps extends TpaTextFieldProps {}

const TextField: React.FC<TextFieldProps> = (props) => {
  return <TpaTextField {...props} className={st(classes.root, {}, props.className)} inputClassName={classes.input} />;
};

TextField.displayName = 'TextField';

export default TextField;

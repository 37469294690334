import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import styles from './OrderFailureModal.scss';
import Button from '../Button';
import dataHooks from '../../data-hooks';
import { OrderFailureType, ErrorCodes, VirtualDispatchType } from '@wix/restaurants-client-logic';
import { SetCheckoutStepPayload } from '../../../../state/checkout/checkout.actions.types';
import { TranslationFunction } from 'i18next';
import { CheckoutStep } from '../../../../core/types/Checkout';
import { IPaymentMethod } from '@wix/cashier-payments-widget/dist/src/cashier-payments';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';

export interface OrderFailureModalProps {
  onRequestClose: () => void;
  resetErrors: () => void;
  errorType?: OrderFailureType;
  errorCode?: number;
  isCouponError?: boolean;
  setCheckoutStep: (payload: SetCheckoutStepPayload) => void;
  t: TranslationFunction;
  hasFutureOrders?: boolean;
  restaurantPhone?: string;
  dispatchType: VirtualDispatchType;
  loyaltyPointsName?: string;
  paymentMethods: IPaymentMethod[];
}

const OrderFailureModal: React.FC<OrderFailureModalProps> = ({
  onRequestClose,
  errorType,
  errorCode,
  isCouponError,
  resetErrors,
  setCheckoutStep,
  t,
  hasFutureOrders,
  restaurantPhone,
  dispatchType,
  loyaltyPointsName,
  paymentMethods,
}) => {
  const { displayableAmountLeft } = useMinOrderPriceDetails();

  const onCloseModal = (step?: CheckoutStep) => {
    step && setCheckoutStep({ step });
    onRequestClose();
    resetErrors();
  };

  const getCTAAndContentByErrorCode = () => {
    let text: string, title: string, details: string[];
    let onClick = () => {
      onCloseModal('address-information');
      window.location.reload();
    };

    switch (errorCode) {
      case ErrorCodes.RESTAURANTS_CLOSED:
        text = hasFutureOrders ? t('error_modal_closed_future_cta') : t('error_modal_closed_cta');
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        title = hasFutureOrders ? t('error_modal_closed_future_title') : t('error_modal_closed_title');
        details = hasFutureOrders
          ? [t('error_modal_closed_future_description')]
          : [t('error_modal_closed_description')];
        break;
      case ErrorCodes.VARIATION_PRICE_UPDATE:
      case ErrorCodes.PRICE_UPDATE:
        text = t('error_modal_price_change_cta');
        title = t('error_modal_price_change_title');
        details = [t('error_modal_price_change_description')];
        break;
      case ErrorCodes.ITEM_UNAVAILABLE_FOR_FULFILLMENT_METHOD:
        text = t('error_modal_dish_visibility_cta');
        title = t('error_modal_dish_visibility_title');
        details = [t('error_modal_dish_visibility_description')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', 'cart');
        };
        break;
      case ErrorCodes.PROMO_CODE_UPDATE:
      case ErrorCodes.DISCOUNT_UPDATE:
        text = isCouponError ? t('error_modal_promo_change_cta') : t('error_modal_discount_change_cta');
        title = isCouponError ? t('error_modal_promo_change_title') : t('error_modal_discount_change_title');
        details = isCouponError
          ? [t('error_modal_promo_change_description')]
          : [t('error_modal_discount_change_description')];
        break;
      case ErrorCodes.PICKUP_UNAVAILABLE:
        text = t('error_modal_pickup_change_cta');
        title =
          dispatchType === 'dine-in' ? t('error_modal_dinein_change_title') : t('error_modal_pickup_change_title');
        details =
          dispatchType === 'dine-in'
            ? [t('error_modal_dinein_change_description')]
            : [t('error_modal_pickup_change_description')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.DINE_IN_UNAVAILABLE:
        text = t('error_modal_pickup_change_cta');
        title = t('error_modal_dinein_change_title');
        details = [t('error_modal_dinein_change_description')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.CURBSIDE_PICKUP_UNAVAILABLE:
        text = t('checkout_main_curbsidepickup_modal_unavailable_cta');
        title = t('checkout_main_curbsidepickup_modal_unavailable_title');
        details = [t('checkout_main_curbsidepickup_modal_unavailable_text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.INVALID_MIN_ORDER_PRICE:
        text = t('checkout_main_minorder_additems_modal_cta');
        title = t('checkout_main_minorder_additems_modal_title');
        details = [t('checkout_main_minorder_additems_modal_text', { amount: displayableAmountLeft })];

        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;

      case ErrorCodes.LOYALTY_MISSING_REQUIRED_FIELD:
        text = t('Loyalty_Error_cta');
        title = t('Loyalty_Error_MissingField_title');
        details = [t('Loyalty_Error_MissingField_Text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;

      case ErrorCodes.LOYALTY_INSUFFICIENT_BALANCE:
        text = t('Loyalty_Error_cta');
        title = loyaltyPointsName
          ? t('Loyalty_Error_Points_NotEnough_title', { pointnameplural: loyaltyPointsName })
          : t('Loyalty_Error_Points_NotEnough_title_default');
        details = loyaltyPointsName
          ? [t('Loyalty_Error_Points_NotEnough_Text', { pointnameplural: loyaltyPointsName })]
          : [t('Loyalty_Error_Points_NotEnough_Text_default')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.LOYALTY_INVALID_POINTS_AMOUNT:
        text = t('Loyalty_Error_cta');
        title = t('Loyalty_Error_InvalidPoints_title');
        details = [t('Loyalty_Error_InvalidPoints_Text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.LOYALTY_REWARD_NOT_ACTIVE:
        text = t('Loyalty_Error_cta');
        title = t('Loyalty_Error_RewardUnavailable_title');
        details = [t('Loyalty_Error_RewardUnavailable_Text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.LOYALTY_UNSUPPORTED_DISCOUNT_TYPE:
        text = t('Loyalty_Error_cta');
        title = t('Loyalty_Error_RewardUnavailable2_title');
        details = [t('Loyalty_Error_RewardUnavailable2_Text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;

      case ErrorCodes.LOYALTY_POINTS_REQUIRED:
        text = t('Loyalty_Error_cta');
        title = t('Loyalty_Error_MinPoints_Change_title');

        details = loyaltyPointsName
          ? [t('Loyalty_Error_MinPoints_Change_Text', { pointnameplural: loyaltyPointsName })]
          : [t('Loyalty_Error_MinPoints_Change_Text_default')];

        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.LOYALTY_UNEXPECTED_CHARGES_AMOUNT:
        text = t('Loyalty_Error_cta');
        title = t('Loyalty_Error_UnexpectedCharge_title');
        details = [t('Loyalty_Error_UnexpectedCharge_Text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;

      case ErrorCodes.TOGGLE_OFF_CHARGE:
        text = isCouponError ? t('error_modal_promo_change_cta') : t('error_modal_discount_change_cta');
        title = isCouponError ? 'Promo code is unavailable' : 'Discount is unavailable';
        details = isCouponError
          ? [t('error_modal_promo_change_description')]
          : [t('error_modal_discount_change_description')];
        break;
      case ErrorCodes.DINE_IN_AND_CURBSIDE_HAVE_BEEN_SUBMITTED:
        text = t('checkout_main_delivery_method_infoerror_cta');
        title = t('checkout_main_delivery_method_infoerror_title');
        details = [t('checkout_main_delivery_method_infoerror_text')];
        break;
      case ErrorCodes.DINE_IN_MISSING_LABEL_VALUE:
      case ErrorCodes.DINE_IN_MISSING_LABEL:
        text = t('checkout_main_delivery_method_dinein_error_cta');
        title = t('checkout_main_delivery_method_dinein_error_title');
        details = [t('checkout_main_delivery_method_dinein_error_text')];
        break;
      case ErrorCodes.DELIVERY_UNAVAILABLE:
        text = t('error_modal_delivery_unavailable_cta');
        title = t('error_modal_delivery_unavailable_title');
        details = [t('error_modal_delivery_unavailable_text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.DELIVERY_TIME_IS_MORE_THEN_MAX_DELIVETY_DURATION:
      case ErrorCodes.DELIVERY_TIME_IS_LESS_THEN_DELIVETY_DURATION:
      case ErrorCodes.DELIVERY_TIME_CANNOT_PRECEDE:
        text = t('error_modal_moretime_cta');
        title = t('error_modal_moretime_title');
        details = [t('error_modal_moretime_text')];
        onClick = () => {
          onCloseModal();
          window.location.href = window.location.href.replace('checkout', '');
        };
        break;
      case ErrorCodes.TOGGLE_OFF_CASH:
        const isOtherPaymentMethodAvailable =
          paymentMethods && paymentMethods.filter((paymentMethod) => paymentMethod.paymentMethod !== 'offline').length;
        text = t('error_modal_payment_method_unavailable_cta');
        title = isOtherPaymentMethodAvailable
          ? t('error_modal_payment_method_unavailable_title')
          : t('error_modal_only_payment_method_unavailable_title');
        details = isOtherPaymentMethodAvailable
          ? [t('error_modal_payment_method_unavailable_description')]
          : [t('error_modal_only_payment_method_unavailable_description')];
        onClick = () => {
          if (isOtherPaymentMethodAvailable) {
            // we need the cashier widget to re-render
            onCloseModal('payments');
            window.location.reload();
          } else {
            onCloseModal();
            window.location.href = window.location.href.replace('checkout', '');
          }
        };
        break;

      case ErrorCodes.USER_PAYMENT_CANCELED:
        text = t('error_modal_canceled_bit_cta');
        title = t('error_modal_canceled_bit_title');
        details = [t('error_modal_canceled_bit_description')];
        onClick = () => {
          onCloseModal();
        };
        break;
      default:
        return undefined;
    }
    return { text, onClick, title, details };
  };

  /**
   * @deprecated errorType is depricated and should not be use. Instead use errorCode
   */
  const getCTA = (): { text: string; onClick: () => void; title: string; details: string[] } => {
    switch (errorType) {
      case 'invalid_data':
        return {
          text: t('error_modal_offline_back_button'),
          onClick: () => {
            onCloseModal();
            window.location.href = window.location.href.replace('checkout', '');
          },
          title: t('error_modal_unavailableitem_title'),
          details: [t('error_modal_unavailableitem_text')],
        };
      case 'out_of_stock':
        return {
          text: t('error_modal_dish_outofstock_cta'),
          onClick: () => {
            window.location.href = window.location.href.replace('checkout', 'cart');
          },
          title: t('error_modal_dish_outofstock_title'),
          details: [t('error_modal_dish_outofstock_description')],
        };
      case 'no_server_response':
        return {
          onClick: onCloseModal,
          text: t('error_modal_connection_CTA'),
          title: t('error_modal_connection_title'),
          details: [
            t('error_modal_connection_subtitle_1'),
            t('error_modal_connection_subtitle_2', { phone: restaurantPhone }),
          ],
        };
      case 'invalid_cc_number':
      case 'cc_expired':
      case 'cc_rejected':
        return {
          text: t('error_modal_verifycard_back_button'),
          onClick: () => {
            onCloseModal('payments');
          },
          title: t('error_modal_verifycard_title'),
          details: [t('error_modal_verifycard_text')],
        };
      case 'address_not_in_range':
        return {
          text: t('error_modal_delivery_area_change_cta'),
          onClick: () => {
            onCloseModal();
            window.location.href = window.location.href.replace('checkout', '');
          },
          title: t('error_modal_delivery_area_change_title'),
          details: [t('error_modal_delivery_area_change_description')],
        };
      case 'unavailable':
      case 'cannot_submit_order':
        return {
          title: t('error_modal_title'),
          details: [t('error_modal_subtitle_1'), t('error_modal_subtitle_2')],
          text: t('error_modal_CTA'),
          onClick: () => {
            onCloseModal('address-information');
            window.location.reload();
          },
        };
      case 'internal':
        return {
          text: t('error_modal_CTA'),
          onClick: onCloseModal,
          title: t('error_modal_servererror_title'),
          details: [t('error_modal_servererror_text')],
        };
      default:
        return {
          text: t('error_modal_CTA'),
          onClick: onCloseModal,
          title: t('error_modal_title'),
          details: [t('error_modal_subtitle_1'), t('error_modal_subtitle_2')],
        };
    }
  };

  const { text, onClick, title, details } = getCTAAndContentByErrorCode() ?? getCTA();
  const header = (
    <Text className={styles.header} typography="header-m" data-hook={dataHooks.orderFailureModalTitle}>
      {title}
    </Text>
  );
  const shouldShowFootnote =
    (errorType === 'invalid_cc_number' || errorType === 'cc_expired' || errorType === 'cc_rejected') &&
    errorCode !== ErrorCodes.TOGGLE_OFF_CASH;

  return (
    <ModalLayoutBasic header={header} onCloseClick={onClick} data-hook={dataHooks.orderFailureModal}>
      <div className={styles.wrapper}>
        <div data-hook={dataHooks.orderFailureModalSubtitle}>
          {details.map((line) => (
            <Text typography="p2-m" tagName="p" key={line}>
              {line}
            </Text>
          ))}
        </div>
        <Button className={styles.okButton} upgrade data-hook={dataHooks.orderFailureModalOkButton} onClick={onClick}>
          {text}
        </Button>
        {shouldShowFootnote && (
          <Text
            typography="p2-xs"
            data-hook={dataHooks.orderFailureModalFooter}
            tagName="p"
            className={styles.subFooter}
          >
            {t('error_modal_verifycard_confirmnocharge_disclaimer')}
          </Text>
        )}
      </div>
    </ModalLayoutBasic>
  );
};

OrderFailureModal.displayName = 'OrderFailureModal';

export default OrderFailureModal;

import React from 'react';
import { Tabs as TpaTabs, TabsProps as TpaTabsProps } from 'wix-ui-tpa/Tabs';
import { st, classes } from './Tabs.st.css';

export interface TabsProps extends TpaTabsProps {}

const Tabs: React.FC<TabsProps> = (props) => {
  return <TpaTabs {...props} className={st(classes.root, {}, '')} />;
};

Tabs.displayName = 'Tabs';

export default Tabs;

import React from 'react';
import { getIcon } from '@wix/restaurants-menus-icons';
import { Tooltip } from 'wix-ui-tpa/Tooltip';
import { TranslationFunction } from 'i18next';
import dataHooks from '../../data-hooks';
import Text from '../../core-components/Text';
import { ReactComponent as StarIcon } from '../../../../assets/icons/star.svg';
import styles from './LabelsView.scss';
import { LabelsSettings } from '@wix/restaurants-client-logic';
import { IWixStyleParams, StyleParamType } from 'yoshi-flow-editor-runtime/tpa-settings';
import { getIconProps, IconProps } from '../../../../core/logic/labelsLogic';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import classNames from 'classnames';

export interface LabelsViewProps {
  labels: string[];
  className?: string;
  styleParams?: IWixStyleParams;
  iconStyleOverride?: IconProps;
  labelsSettings?: LabelsSettings;
  t: TranslationFunction;
  disabled?: boolean;
}

const tmpLabelsKeys: Record<string, string> = {
  hot: 'hot',
  spicy2: 'hot',
  'extra-hot': 'extrahot',
  spicy3: 'extrahot',
  'gluten-free': 'gf',
  organic: 'organic',
  spicy: 'spicy',
  spicy1: 'spicy',
  vegan: 'vegan',
  vegetarian: 'vegetarian',
  featured: 'special',
};

const SPECIAL_KEY = 'featured';

const getLabelElement = (
  label: string,
  t: TranslationFunction,
  labelsSettings?: LabelsSettings,
  iconStyleOverride?: IconProps,
  disabled?: boolean,
  color1?: string,
  color3?: string,
) => {
  const iconProps = iconStyleOverride ? iconStyleOverride : getIconProps(labelsSettings);

  if (disabled) {
    iconProps.primaryColor = color3;
    iconProps.secondaryColor = color1;
  }

  const Icon = label !== SPECIAL_KEY ? getIcon(iconProps.iconType, label) : null;
  return Icon ? (
    <Icon size={24} {...iconProps} />
  ) : (
    <div
      className={classNames([styles.featuredLabel, disabled && styles.disabled])}
      data-hook="wixrest-menus-icon-outline-featured"
    >
      <StarIcon />
      <Text typography="p2-xs-colorless">{t('dish_modal_label_special')}</Text>
    </div>
  );
};

function extractColors(getColor: Function) {
  return {
    color1: getColor({ type: StyleParamType.Color, key: 'color-1' })?.value || '',
    color3: getColor({ type: StyleParamType.Color, key: 'color-3' })?.value || '',
  };
}

const LabelsView: React.FC<LabelsViewProps> = ({
  labels,
  className,
  labelsSettings,
  iconStyleOverride,
  t,
  disabled,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [counter, setCounter] = React.useState(0);
  const labelsWidth = labels.indexOf(SPECIAL_KEY) === -1 ? labels.length * 24 : (labels.length - 1) * 24 + 78;
  const { get: getColor } = useStyles();
  const { color1, color3 } = extractColors(getColor);

  React.useEffect(() => {
    if (ref.current && ref.current.clientWidth && ref.current.clientWidth < labelsWidth) {
      // if ref.current.clientWidth === 0 - it's tests environment
      setCounter(Math.ceil((labelsWidth - ref.current.clientWidth) / 24) + 1);
    }
  }, [ref.current?.clientWidth, labelsWidth]);

  const shouldOverrideStyle = Boolean(iconStyleOverride);
  return labels.length ? (
    <div className={`${styles.wrapper} ${className}`} data-hook={dataHooks.itemViewLabels} ref={ref}>
      {labels.slice(0, labels.length - counter).map((label) => {
        const shouldDisableTooltip = label === SPECIAL_KEY || shouldOverrideStyle;
        const shouldNotRenderTooltip = label === SPECIAL_KEY && shouldOverrideStyle; // don't show 'special' on settings widget
        const labelElement = getLabelElement(label, t, labelsSettings, iconStyleOverride, disabled, color1, color3);
        return !shouldNotRenderTooltip ? (
          <Tooltip
            key={label}
            content={t(`dish_modal_label_${tmpLabelsKeys[label]}`)}
            placement="bottom"
            appendTo="window"
            zIndex={1020}
            disabled={shouldDisableTooltip}
          >
            <div data-hook={dataHooks.itemViewLabelsIcon} className={styles.label}>
              {labelElement}
            </div>
          </Tooltip>
        ) : null;
      })}
      {counter ? (
        <Tooltip
          content={
            <div>
              {labels.slice(labels.length - counter).map((label) => (
                <div>{t(`dish_modal_label_${tmpLabelsKeys[label]}`)}</div>
              ))}
            </div>
          }
          placement="bottom"
          appendTo="window"
          zIndex={1020}
        >
          <Text typography="p2-s">+{counter}</Text>
        </Tooltip>
      ) : null}
    </div>
  ) : null;
};

LabelsView.displayName = 'LabelsView';

export default LabelsView;

import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { isAvailableOn } from 'availability';
import Dropdown from '../Dropdown';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { TimingOption } from '../DispatchSettings/DispatchSettings';
import styles from './DispatchTimeSelector.scss';
import { TranslationFunction } from 'i18next';
import {
  BusinessNotifications,
  DispatchInfo,
  Restaurant,
  VirtualDispatchType,
  Address,
  isAvailabilityIteratorAvailable,
  DispatchTypeAvailability,
} from '@wix/restaurants-client-logic';
import {
  getAsapText,
  getDishPrepareTime,
  getDispatchTimeSelectorDetails,
  getTimeDropdownOptions,
} from './DispatchTimeSelector.helper';
import { BusinessNotificationDetails } from '../../../../state/selectors/businessNotificationSelector';
import { getDispatchTypeFromVirtual } from '../../../../core/logic/dispatchLogic';
import { PartialLocation } from '../../../../core/oloApi';
import { SetErrorVisibilityPayload } from '../../../../state/addressInformationForm/addressForm.actions.types';
import { useExperiments } from 'yoshi-flow-editor-runtime';

export interface DispatchTimeProps {
  restaurant: Restaurant | PartialLocation;
  dispatchType: VirtualDispatchType;
  timingOption?: TimingOption;
  dispatchTime?: number;
  onChange: (changes: { timingOption: TimingOption; selectedDateTime?: number }) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  t: TranslationFunction;
  isMobile?: boolean;
  error?: string;
  idealDeliveryArea?: DispatchInfo;
  businessNotification?: BusinessNotificationDetails;
  isRTL?: boolean;
  isModal?: boolean;
  isMl?: boolean;
  address?: Address;
  supportedDispatchTypesV2: Partial<Record<VirtualDispatchType, DispatchTypeAvailability>>;
}

export const DispatchTime = ({
  timingOption = 'asap',
  dispatchTime,
  restaurant,
  dispatchType,
  onChange,
  setErrorVisibility,
  t,
  isMobile,
  error,
  idealDeliveryArea,
  businessNotification,
  isRTL,
  isModal,
  isMl,
  address,
  supportedDispatchTypesV2,
}: DispatchTimeProps) => {
  const { timezone, deliveryInfos } = restaurant;
  const asapText = getAsapText({
    timezone,
    deliveryInfos,
    dispatchType,
    locale: restaurant.locale,
    t,
    idealDeliveryArea,
  });

  const {
    dateError,
    timeError,
    dateOptions,
    timeOptions,
    finalAvailability,
    finalAvailabilityIteratorFactory,
    selectedDate,
  } = getDispatchTimeSelectorDetails({
    timingOption,
    dispatchTime,
    restaurant,
    dispatchType,
    t,
    error,
    idealDeliveryArea,
    address,
  });

  // update form error visibility if we have date or time error
  useEffect(() => {
    if (dateError || timeError) {
      setErrorVisibility({ error: 'timingOption', value: true });
    } else {
      setErrorVisibility({ error: 'timingOption', value: false });
    }
  }, [dateError, timeError, setErrorVisibility]);

  const isDeliveryType = dispatchType === 'delivery';

  const earliestTimeOptions = useMemo(
    () =>
      dateOptions[0]?.id
        ? getTimeDropdownOptions(
            finalAvailability,
            timezone,
            parseInt(dateOptions[0].id, 10),
            restaurant.orders.future.delayMins,
            getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType)),
            restaurant.locale,
            finalAvailabilityIteratorFactory,
          )
        : [],
    [dateOptions, dispatchType, finalAvailability, finalAvailabilityIteratorFactory, restaurant, timezone],
  );

  const now = moment().tz(timezone);

  let isAvailableNow = finalAvailabilityIteratorFactory
    ? isAvailabilityIteratorAvailable(finalAvailabilityIteratorFactory(now))
    : isAvailableOn(finalAvailability, now);
  let isOnlyAsap = (!isMl || isAvailableNow) && restaurant.orders.future.disabled && !restaurant.orders.asap.disabled;
  let isOnlyFutureOrders =
    isDeliveryType && !isMl
      ? businessNotification?.notification === BusinessNotifications.OnlyFutureOrders
      : !restaurant.orders.future.disabled && earliestTimeOptions[0]?.id && !isAvailableNow;

  const { experiments } = useExperiments();

  if (experiments.enabled('specs.restaurants.olo-client-dtl-v2')) {
    const willBeAvailable = Boolean(supportedDispatchTypesV2[dispatchType]?.willBeAvailable);

    isAvailableNow = Boolean(supportedDispatchTypesV2[dispatchType]?.isAvailable);
    isOnlyAsap = isAvailableNow && !supportedDispatchTypesV2[dispatchType]?.willBeAvailable;
    isOnlyFutureOrders = !isAvailableNow && willBeAvailable;
  }

  if (isOnlyFutureOrders) {
    timingOption = 'later';
  } else if (isOnlyAsap) {
    timingOption = 'asap';
  }

  const shouldChooseDateAndTime =
    !restaurant.orders.future.disabled &&
    (timingOption === 'later' || restaurant.orders.asap.disabled || isOnlyFutureOrders);

  const timingOptionError = timingOption === 'asap' ? error : undefined;

  // hack to set the default dispatch time
  useEffect(() => {
    if (isMl) {
      onChange({
        timingOption,
        selectedDateTime:
          timingOption === 'later' ? parseInt(_.get(earliestTimeOptions, '[0].id', '0'), 10) : undefined,
      });
      setErrorVisibility({ error: 'timingOption', value: false });
    }
  }, [dispatchType, timingOption]);

  if (dateOptions.length === 0 && isModal) {
    return null;
  }

  const labelText = isDeliveryType ? t('checkout_main_delivery_time') : t('checkout_main_pickup_time');
  const shouldDisplayTimingOptionsDropdown =
    !isOnlyFutureOrders && !restaurant.orders.future.disabled && !restaurant.orders.asap.disabled;

  return (
    <div data-hook={dataHooks.dispatchTimeSelector} className={styles.wrapper}>
      {!shouldDisplayTimingOptionsDropdown && (
        <Text typography="p2-s" className={styles.label}>
          {labelText}
        </Text>
      )}
      {isOnlyAsap && (
        <Text typography="p2-m" className={styles.label} data-hook={dataHooks.asapTextWithoutDropdown}>
          {asapText}
        </Text>
      )}
      {shouldDisplayTimingOptionsDropdown && (
        <Dropdown
          label={labelText}
          key={asapText}
          className={styles.timing}
          upgrade
          data-hook={dataHooks.dispatchSummaryTimingOption}
          options={[
            { id: 'asap', value: asapText, isSelectable: true },
            { id: 'later', value: t('checkout_main_specific_time'), isSelectable: true },
          ]}
          onChange={({ id }) => {
            const newTimingOption: TimingOption = id as TimingOption;
            onChange({
              timingOption: newTimingOption,
              selectedDateTime:
                newTimingOption === 'later'
                  ? dispatchTime || (timeOptions.length && Number(timeOptions[0].id))
                  : undefined,
            });
          }}
          initialSelectedId={timingOption}
          error={Boolean(timingOptionError)}
          errorMessage={timingOptionError}
          mobileNativeSelect
        />
      )}
      {shouldChooseDateAndTime && (
        <React.Fragment>
          <div className={`${styles.dateTimeWrapper} ${isMobile && styles.mobile}`}>
            <div id="date-selector" className={styles.date}>
              <Dropdown
                label={t('checkout_main_delivery_day')}
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingDate}
                options={dateOptions}
                onChange={(selectedOption) => {
                  const selectedTime = timeOptions.find((to) => to.id === String(dispatchTime));
                  const newTimeOptions = getTimeDropdownOptions(
                    finalAvailability,
                    restaurant.timezone,
                    Number(selectedOption.id),
                    restaurant.orders.future.delayMins,
                    getDishPrepareTime(restaurant, getDispatchTypeFromVirtual(dispatchType)),
                    restaurant.locale,
                    finalAvailabilityIteratorFactory,
                  );
                  const newSelectedDateTime =
                    (selectedTime && Number(newTimeOptions.find((to) => to.value === selectedTime.value)?.id)) ||
                    Number(newTimeOptions[0].id);

                  onChange({
                    timingOption,
                    selectedDateTime: newSelectedDateTime || Number(selectedOption.id),
                  });
                }}
                initialSelectedId={String(selectedDate)}
                error={Boolean(dateError)}
                errorMessage={dateError}
                mobileNativeSelect
                appendTo={isModal ? 'scrollParent' : undefined}
              />
            </div>
            <div className={`${styles.spacer} ${isMobile && styles.mobile}`} />
            <div id="time-selector" className={styles.time}>
              <Dropdown
                label={t('checkout_main_delivery_hour')}
                upgrade
                data-hook={dataHooks.dispatchSummaryTimingTime}
                options={timeOptions}
                onChange={(selectedOption) => {
                  onChange({ timingOption, selectedDateTime: Number(selectedOption.id) });
                }}
                initialSelectedId={dispatchTime ? String(dispatchTime) : timeOptions[0]?.id}
                error={Boolean(timeError)}
                errorMessage={timeError}
                disabled={Boolean(!timeError && dateError)}
                errorTooltipPlacement={isRTL ? 'top-start' : 'top-end'}
                mobileNativeSelect
                appendTo={isModal ? 'scrollParent' : undefined}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

DispatchTime.displayName = 'DispatchTime';

export default React.memo(DispatchTime);

import { AlignmentOptions } from '@wix/restaurants-client-logic';
import { ISettingsParam, ISettingsParams } from 'yoshi-flow-editor-runtime/tpa-settings';
import { SIDE_BY_SIDE } from './SharedSettings/Constants';

export type IComponentSettings = ISettingsParams<{
  layout: ISettingsParam<'side-by-side' | 'card'>;
  showDishImages: ISettingsParam<boolean>;
  showSectionImages: ISettingsParam<boolean>;
  showDescriptions: ISettingsParam<boolean>;
  showLabels: ISettingsParam<boolean>;
  hasMembersAreaIntegration: ISettingsParam<boolean>;
  showMenuDescription: ISettingsParam<boolean>;
  showSectionDescription: ISettingsParam<boolean>;
  dishCardAlignment: ISettingsParam<AlignmentOptions>;
  menuHeaderAlignment: ISettingsParam<AlignmentOptions>;
}>;

export let componentSettings: IComponentSettings = {
  layout: {
    key: 'layout',
    getDefaultValue: () => SIDE_BY_SIDE,
  },
  showDishImages: {
    key: 'showDishImages',
    getDefaultValue: () => true,
  },
  showSectionImages: {
    key: 'showSectionImages',
    getDefaultValue: () => true,
  },
  showDescriptions: {
    key: 'showDescriptions',
    getDefaultValue: () => true,
  },
  showLabels: {
    key: 'showLabels',
    getDefaultValue: () => true,
  },
  hasMembersAreaIntegration: {
    key: 'hasMembersAreaIntegration',
    getDefaultValue: () => false,
  },
  showMenuDescription: {
    key: 'showMenuDescription',
    getDefaultValue: () => true,
  },
  showSectionDescription: {
    key: 'showSectionDescription',
    getDefaultValue: () => true,
  },
  dishCardAlignment: {
    key: 'dishCardAlignment',
    getDefaultValue: () => AlignmentOptions.EMPTY,
  },
  menuHeaderAlignment: {
    key: 'menuHeaderAlignment',
    getDefaultValue: () => AlignmentOptions.EMPTY,
  },
};

export const __setComponentSettings = (newComponentSettings: IComponentSettings) => {
  componentSettings = newComponentSettings;
};

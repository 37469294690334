import React from 'react';
import { Button as TpaButton, ButtonProps as TpaButtonProps } from 'wix-ui-tpa/Button';
import { Spinner } from 'wix-ui-tpa/Spinner';
import dataHooks from '../../data-hooks';
import { classes, st } from './Button.st.css';

export interface ButtonProps extends TpaButtonProps {
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ loading, ...rest }) => {
  return (
    <TpaButton {...rest} className={st(classes.root, {}, rest.className)}>
      {loading ? (
        <Spinner diameter={18} className={classes.spinner} data-hook={dataHooks.buttonSpinner} />
      ) : (
        rest.children
      )}
    </TpaButton>
  );
};

Button.displayName = 'Button';

export default Button;

import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import moment from 'moment-timezone';
import { getDisplayableMenu } from '@wix/restaurants-client-logic';

export const getTakeoutDisplayableMenuSelector = createSelector(
  (state: AppState) => state.session.menu,
  (state: AppState) => state.session.restaurant.locale,
  (state: AppState) => state.session.restaurant.currency,
  (state: AppState) => state.session.restaurant.timezone,
  (state: AppState) => (state.platformParams.isMobile ? 'mobileweb' : 'web'),
  (state: AppState) => state.checkout.dispatch.time,
  (menu, locale, currency, timezone, mobile, time) => {
    const momentTime = moment(time).tz(timezone);
    return getDisplayableMenu(menu, locale, currency, momentTime, mobile, 'takeout');
  },
);

export const getDeliveryDisplayableMenuSelector = createSelector(
  (state: AppState) => state.session.menu,
  (state: AppState) => state.session.restaurant.locale,
  (state: AppState) => state.session.restaurant.currency,
  (state: AppState) => state.session.restaurant.timezone,
  (state: AppState) => (state.platformParams.isMobile ? 'mobileweb' : 'web'),
  (state: AppState) => state.checkout.dispatch.time,
  (menu, locale, currency, timezone, mobile, time) => {
    const momentTime = moment(time).tz(timezone);
    return getDisplayableMenu(menu, locale, currency, momentTime, mobile, 'delivery');
  },
);

export const getDisplayableMenuSelector = createSelector(
  (state: AppState) => state.checkout.dispatch.type,
  (state: AppState) => getTakeoutDisplayableMenuSelector(state),
  (state: AppState) => getDeliveryDisplayableMenuSelector(state),
  (type, takeoutDisplayableMenu, deliveryDisplayableMenu) => {
    if (type === 'takeout') {
      return takeoutDisplayableMenu;
    }

    return deliveryDisplayableMenu;
  },
);

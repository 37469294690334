import React from 'react';
import styles from './ModalLayout.scss';
import Text from '../../core-components/Text';
import { getInterpolateFunction } from '../../../../core/logic/interpolate';
import { ReactComponent as Close } from '../../../../assets/icons/close.svg';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import dataHooks from '../../data-hooks';

export interface ModalLayoutProps {
  children?: React.ReactNode;
  parallax?: React.ReactNode;
  header: React.ReactNode;
  footer?: React.ReactNode;
  onCloseClick: () => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  'data-hook'?: string;
  headerStyle?: 'centered';
}

function lockScroll() {
  unlockScroll().then(() => {
    disableBodyScroll(document.getElementById('lock-modal')!);
  });
}

function unlockScroll() {
  return new Promise((resolve) => {
    clearAllBodyScrollLocks();
    setTimeout(resolve, 16);
  });
}

export interface ModalLayoutState {
  scroll: number;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

class ModalLayout extends React.Component<ModalLayoutProps, ModalLayoutState> {
  static displayName = 'ModalLayout';
  fixedHeaderRef = React.createRef<HTMLDivElement>();
  bigTitleRef = React.createRef<HTMLDivElement>();
  imageWrapperRef = React.createRef<HTMLDivElement>();
  interpolateBackgroundOpacity: (n: number) => number;
  interpolateTextTransform: (n: number) => number;
  interpolateTextOpacity: (n: number) => number;

  constructor(props: ModalLayoutProps) {
    super(props);
    this.state = { scroll: -1000, scrollContainerRef: React.createRef<HTMLDivElement>() };
    const textInputRange: [number, number] = props.parallax ? [-30, 30] : [-60, 0];
    this.interpolateBackgroundOpacity = getInterpolateFunction([-134, -68], [0, 1]);
    this.interpolateTextTransform = getInterpolateFunction(textInputRange, [34, 0]);
    this.interpolateTextOpacity = getInterpolateFunction(textInputRange, [0, 1]);
  }

  static getDerivedStateFromProps(
    { scrollContainerRef: scrollContainerRefProp }: ModalLayoutProps,
    { scrollContainerRef: scrollContainerRefState }: ModalLayoutState,
  ) {
    if (scrollContainerRefProp && scrollContainerRefProp !== scrollContainerRefState) {
      return { scrollContainerRef: scrollContainerRefProp };
    }

    return null;
  }

  componentDidMount() {
    lockScroll();
    this.handleScroll();
  }

  componentWillUnmount() {
    unlockScroll();
  }

  handleScroll = () => {
    const { scrollContainerRef, scroll } = this.state;
    const imageWrapperHeight = this.imageWrapperRef.current ? this.imageWrapperRef.current.offsetHeight : 0;
    const titleHeight = this.bigTitleRef.current?.getBoundingClientRect().height || 0;
    const fixedHeaderHeight =
      this.fixedHeaderRef.current && !this.props.parallax ? this.fixedHeaderRef.current.offsetHeight : 0;
    const newScroll = scrollContainerRef.current
      ? scrollContainerRef.current.scrollTop - imageWrapperHeight - fixedHeaderHeight - titleHeight
      : -1000;

    if (newScroll !== scroll) {
      this.setState({ scroll: newScroll });
    }
  };

  render() {
    const { scroll, scrollContainerRef } = this.state;
    const { parallax, header, children, footer, onCloseClick, headerStyle } = this.props;
    const backgroundOpacity = this.interpolateBackgroundOpacity(scroll);
    const isCloseHiddenBeforeScroll = !parallax || backgroundOpacity === 1;
    const isCloseHiddenAfterScroll = backgroundOpacity !== 1;

    return (
      <div className={styles.wrapper} data-hook={this.props['data-hook']}>
        <>
          <button
            data-hook={dataHooks.modalCloseButton}
            onClick={() => onCloseClick()}
            className={styles.closeIcon}
            aria-label="Close"
            aria-hidden={isCloseHiddenBeforeScroll}
            tabIndex={isCloseHiddenBeforeScroll ? -1 : 0}
            autoFocus
          >
            <Close fill="#000" />
          </button>
          <div
            className={`${styles.fixedHeader} ${parallax && styles.withParallax}`}
            style={{ opacity: parallax ? backgroundOpacity : 1 }}
            ref={this.fixedHeaderRef}
          >
            <div
              className={styles.fixedHeaderText}
              style={{
                opacity: this.interpolateTextOpacity(scroll),
                transform: `translateY(${this.interpolateTextTransform(scroll)}px)`,
              }}
            >
              <Text typography="header-xs-responsive" data-hook="modal-layout-title-small" className={styles.ellipsis}>
                <span aria-hidden={backgroundOpacity !== 1}>{header}</span>
              </Text>
            </div>

            <button
              data-hook={dataHooks.modalCloseButton}
              onClick={() => onCloseClick()}
              className={styles.closeIcon}
              aria-label="Close"
              aria-hidden={isCloseHiddenAfterScroll}
              tabIndex={isCloseHiddenAfterScroll ? -1 : 0}
            >
              <Close fill="#000" />
            </button>
          </div>
        </>

        <div className={styles.innerWrapper} onScroll={this.handleScroll} ref={scrollContainerRef} id="lock-modal">
          {parallax && (
            <div
              className={styles.image}
              ref={this.imageWrapperRef}
              style={{
                transform: `translateY(${
                  scrollContainerRef.current ? scrollContainerRef.current.scrollTop * 0.5 : 0
                }px`,
              }}
            >
              <div className={styles.parallax}>{parallax}</div>
            </div>
          )}
          <div>
            <Text
              typography="header-m"
              data-hook="modal-layout-title"
              className={`${styles.header} ${parallax && styles.parallaxHeader} ${headerStyle && styles[headerStyle]}`}
            >
              <Text
                typography={headerStyle === 'centered' ? 'header-s' : 'header-m'}
                id="modalTitle"
                aria-hidden={!parallax || backgroundOpacity === 1}
              >
                <span ref={this.bigTitleRef}>{header}</span>
              </Text>
            </Text>
          </div>
          <div className={styles.content}>{children}</div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>
    );
  }
}

export default ModalLayout;

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ViewModeOOI } from '@wix/cashier-payments-widget/dist/src/types/ViewMode';
import { setCashierPaymentDone } from '../../../../state/checkout/checkout.actions';
import { AppState } from '../../../../state/createStore';
import Cashier from './Cashier';
import { DeliveryDispatch } from '@wix/restaurants-client-logic';

function mapStateToProps(state: AppState) {
  const isDeliveryPartner = state.checkout.deliveryProvider !== undefined;
  return {
    instance: state.platformParams.instance,
    paymentMethod: state.checkout.pendingCashierPayment.paymentMethod,
    language: state.session.siteLanguage || state.session.restaurant.locale.split('_')[0],
    address: (state.checkout.dispatch as DeliveryDispatch).address,
    restaurantAddress: state.session.restaurant.address,
    contact: state.checkout.contact,
    dispatchType: state.checkout.dispatch.type,
    cashierToken: state.checkout.cashierToken,
    signedInstance: state.platformParams.signedInstance || '',
    viewMode: state.platformParams.viewMode as ViewModeOOI,
    isMobile: state.platformParams.isMobile,
    isUserLoggedIn: state.session.isUserLoggedIn,
    currency: state.session.restaurant.currency,
    isMAInstalled: state.session.isMAInstalled,
    allowManualPayment: !isDeliveryPartner,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setCashierPaymentDone,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Cashier);


import React from 'react';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';
import { Address } from '@wix/restaurants-client-logic';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import EmbeddedMap from '../EmbeddedMap';
import styles from './ThankYou.scss';

interface AddressInformationTakeoutProps {
  restaurantName: string;
  address: Address;
  t: TranslationFunction;
}

const ThankYouRestaurantDetails: React.FC<AddressInformationTakeoutProps> = ({ restaurantName, address, t }) => {
  return (
    <div className={styles.takeoutWrapper}>
      <div className={styles.restaurantDetailsWrapper}>
        <div className={styles.restaurantDetails} data-hook={dataHooks.thankYouRestaurantAddress}>
          <Text typography="p2-m" className={styles.takeoutLabel}>
            {t('order_settings_modal_pickup_label') + ':'}
          </Text>
          {restaurantName && (
            <Text typography="p2-m" data-hook={dataHooks.thankYouRestaurantLocationName}>
              {restaurantName}
            </Text>
          )}
          <Text typography="p2-m"> {address.formatted} </Text>
          {address.comment && <Text typography="p2-m"> {address.comment} </Text>}
        </div>
      </div>
      <EmbeddedMap className={styles.map} address={address} />
    </div>
  );
};

ThankYouRestaurantDetails.displayName = 'ThankYouRestaurantDetails';

export default translate()(ThankYouRestaurantDetails);

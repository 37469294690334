import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import styles from './LogoutModal.scss';
import dataHooks from '../../data-hooks';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter/ModalLayoutBasicFooter';
import { TranslationFunction } from 'i18next';

export interface LogoutModalProps {
  onRequestClose: () => void;
  email: string;
  onLogoutClick: any;
  isMobile?: boolean;
  t: TranslationFunction;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ onRequestClose, onLogoutClick, email, isMobile, t }) => {
  const onCloseModal = () => {
    onRequestClose();
  };

  const header = (
    <Text className={styles.header} typography="header-m" data-hook={dataHooks.memberLogoutModalTitle}>
      {t('checkout_main_loginout_modal_logout.title')}
    </Text>
  );
  const footer = (
    <ModalLayoutBasicFooter
      centered
      equalWidthButtons
      isMobile={isMobile}
      cancelButtonContent={t('checkout_main_loginout_modal_logout.return.cta')}
      onCancelClick={onCloseModal}
      onOkClick={onLogoutClick}
      primaryButtonContent={t('checkout_main_loginout_modal_logout.logout.cta')}
      className={isMobile ? styles.mobileLogoutBtn : undefined}
    />
  );
  return (
    <ModalLayoutBasic
      header={header}
      footer={footer}
      onCloseClick={onCloseModal}
      data-hook={dataHooks.memberLogoutModal}
    >
      <div className={styles.wrapper}>
        <div data-hook={dataHooks.memberLogoutModalSubtitle}>
          <Text typography="p2-m" tagName="p">
            {t('checkout_main_loginout_modal_logout.text', { email })}
          </Text>
        </div>
      </div>
    </ModalLayoutBasic>
  );
};

LogoutModal.displayName = 'LogoutModal';

export default LogoutModal;

import {
  getAllBusinessNotifications,
  getCachedTranslationFunction,
  getIdealDeliveryArea,
  Restaurant,
  TimingOption,
  Address,
} from '@wix/restaurants-client-logic';
import { useTranslation } from 'yoshi-flow-editor-runtime';
import { deliveryAreaSelectorByAddressForm } from '../../state/selectors/deliveryAreaSelector';
import { getVirtualDispatchTypeFromDispatch } from '../logic/dispatchLogic';
import { PartialLocation } from '../oloApi';
import { useAppSelector } from './redux';

// returns <DispatchTimeSelector /> Props per location
export default function useDispatchTimeSelectorProps(location?: PartialLocation, address?: Address) {
  const { t } = useTranslation();
  const stateToProps = useAppSelector((state) => {
    const isML = state.session.isMultiLocation;
    const restaurant = state.session.restaurant;
    const hasAnyPaymentMethods = state.session.paymentMethods.length > 0;

    const notifications = getAllBusinessNotifications(
      isML && location
        ? ({
            ...location,
            products: restaurant.products,
          } as Restaurant)
        : restaurant,
      hasAnyPaymentMethods,
    );

    return {
      isMobile: state.platformParams.isMobile,
      isRTL: state.platformParams.isRTL,
      dispatchTime: state.checkout.dispatch.time,
      dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
      idealDeliveryArea:
        isML && location
          ? getIdealDeliveryArea({
              dispatchTime: state.checkout.dispatch.time,
              address: address as Address,
              restaurant: location,
              deliveryPartnerProps: {
                deliveryPartnerFee: undefined,
                shouldConsiderDeliveryPartner: true,
              },
            }).dispatchInfo
          : deliveryAreaSelectorByAddressForm(state)?.dispatchInfo,
      restaurant: isML && location ? location : restaurant,
      businessNotification: notifications.length > 0 ? notifications[0] : undefined,
      address,
    };
  });

  return {
    t: getCachedTranslationFunction(t),
    timingOption: (stateToProps.dispatchTime ? 'later' : 'asap') as TimingOption,
    ...stateToProps,
  };
}

import { AppState } from '../../../../../state/createStore';
import { bindActionCreators, Dispatch } from 'redux';
import {
  setAddressInputValue,
  setDeliveryAddressField,
  setFieldError,
  setSelectedAddress,
} from '../../../../../state/addressInformationForm/addressForm.actions';
import AddressInformationDelivery from './AddressInformationDelivery';
import { connect } from 'react-redux';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ownProps: any) {
  return {
    ...ownProps,
    validateAddressReason: state.addressForm.addressInputError,
    addressInput: state.addressForm.addressInputValue,
    selectedAddress: state.addressForm.selectedAddressOption,
    errorsVisibility: state.addressForm.errorsVisibility,
    fieldsErrors: state.addressForm.fieldErrors,
    isMobile: state.platformParams.isMobile,
    addressInputValue: state.addressForm.addressInputValue,
    signedInstance: state.platformParams.signedInstance,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setSelectedAddress,
      setAddressInputValue,
      setDeliveryAddressField,
      setFieldError,
    },
    dispatch,
  );
}

export default translate()(connect(mapStateToProps, mapDispatchToProps)(AddressInformationDelivery));

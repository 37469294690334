import * as Long from 'long';
import { AppState } from '../createStore';
import { createSelector } from 'reselect';
import { Reward, RewardType } from '@wix/ambassador-loyalty-rewards/types';
import { LoyaltyAccount } from '@wix/ambassador-loyalty-accounts/types';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-programs/types';
import { hasLoyaltySupport, isLoyaltyDiscount, Menu } from '@wix/restaurants-client-logic';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/types';
import _ from 'lodash';

function loyaltyProgramSelectorInner(
  menu: Menu,
  loyaltyAccount?: LoyaltyAccount,
  loyaltyRewards?: Reward[],
  loyaltyProgram?: LoyaltyProgram,
  loyaltyEarningRules?: EarningRule[],
) {
  const { chargesV2 = [] } = menu;
  const discountAmountReward = loyaltyRewards?.find((r) => r.type === RewardType.DISCOUNT_AMOUNT && r.active);
  const isLoyaltyProgramActive = loyaltyProgram?.status === ProgramStatus.ACTIVE;
  const activityPrerequisite = hasLoyaltySupport(chargesV2) && isLoyaltyProgramActive;
  const loyaltyRewardRevision = discountAmountReward?.revision || Long.ZERO;

  return {
    pointsRequired: discountAmountReward?.requiredPoints || 0,
    currencyValue: Number(discountAmountReward?.discountAmount?.amount || 0),
    pointsBalance: loyaltyAccount?.points?.balance || 0,
    loyaltyRewardId: discountAmountReward?.id, // TODO we need to change this discountAmountReward to appear only if loyalty is there
    customPointsName: loyaltyProgram?.points?.customName,
    loyaltyProgramName: loyaltyProgram?.name,
    loyaltyChargesV2: chargesV2.filter(isLoyaltyDiscount),
    isLoyaltyRedeemActive: activityPrerequisite && Boolean(discountAmountReward),
    isLoyaltyEarnActive: activityPrerequisite && !_.isEmpty(loyaltyEarningRules),
    loyaltyEarningRules,
    loyaltyRewardRevision, // TODO we need to change this discountAmountReward to appear only if loyalty is there
  };
}

export const loyaltyProgramSelector = createSelector(
  [
    (state: AppState) => state.session.menu,
    (state: AppState) => state.session.loyaltyAccount,
    (state: AppState) => state.session.loyaltyRewards,
    (state: AppState) => state.session.loyaltyProgram,
    (state: AppState) => state.session.loyaltyEarningRules,
  ],
  loyaltyProgramSelectorInner,
);

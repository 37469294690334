import React from 'react';
import ModalLayoutSelection from '../ModalLayoutSelection';
import ModalLayoutBasicFooter from '../ModalLayoutBasicFooter';
import { Address } from '@wix/ambassador-addresses-web/types';
import RadioButton from '../RadioButton';
import styles from './AddressSelectionModal.scss';
import { ReactComponent as Error } from 'wix-ui-tpa/dist/statics/assets/icons/Error.svg';
import { SectionNotification, TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/SectionNotification';
import { TextButton } from '../TextButton';
import dataHooks from '../../data-hooks';
import {
  SetDeliveryAddressPayload,
  SetSelectedAddressIdPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { translate, useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import AddressInformationDelivery from '../AddressInformation/AddressInformationDelivery';
import { Restaurant } from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import DrillDownComponent from '../DrillDownComponent';
import {
  ValidateAddressReason,
  Address as OloAddress,
  validateAddress,
  validateAddressForLocations,
} from '@wix/restaurants-client-logic';
import { SaveAddressToServerPayload } from '../../../../state/session/session.actions.types';
import {
  SetAddressInputErrorPayload,
  SetErrorVisibilityPayload,
  SetSelectedAddressPayload,
} from '../../../../state/addressInformationForm/addressForm.actions.types';
import {
  convertMembersAddressToOloAddress,
  convertToOloAddressMembersAddress,
} from '../../../../core/logic/addressLogic';
import Checkbox from '../Checkbox';
import { getDisplayableAddressError } from '../AddressInformation/AddressInformation.helper';
import { PartialLocation } from '../../../../core/oloApi';

export interface AddressSelectionModalProps {
  addresses: Address[];
  onRequestClose: () => void;
  defaultAddressId?: string;
  setDeliveryAddress: (address: SetDeliveryAddressPayload) => void;
  t: TranslationFunction;
  forceAddressFormVisibility?: boolean;
  restaurant: Restaurant;
  dispatchTime?: number;
  totalOrderPrice: number;
  setDeliveryAddressFromForm: () => void;
  addressInputError?: ValidateAddressReason;
  selectedAddressOption?: OloAddress;
  saveAddressToServer: (address: SaveAddressToServerPayload) => void;
  setAddressInputError: (payload: SetAddressInputErrorPayload) => void;
  setErrorVisibility: (payload: SetErrorVisibilityPayload) => void;
  setSelectedAddress: (payload: SetSelectedAddressPayload) => void;
  selectedAddressId?: string;
  setSelectedAddressId: (payload: SetSelectedAddressIdPayload) => void;
  isLoadingAddressesFromServer: boolean;
  initAddressForm: Function;
  setFieldError: (payload: SetErrorVisibilityPayload) => void;
  locations: PartialLocation[];
  isMultiLocation?: boolean;
}

const AddressSelectionModal: React.FC<AddressSelectionModalProps> = ({
  onRequestClose,
  addresses,
  defaultAddressId,
  setDeliveryAddress,
  t,
  forceAddressFormVisibility,
  restaurant,
  dispatchTime,
  totalOrderPrice,
  setDeliveryAddressFromForm,
  selectedAddressOption,
  saveAddressToServer,
  setAddressInputError,
  setErrorVisibility,
  setSelectedAddress,
  selectedAddressId,
  setSelectedAddressId,
  isLoadingAddressesFromServer,
  initAddressForm,
  setFieldError,
  locations,
  isMultiLocation,
}) => {
  const [localSelectedAddressId, setLocalSelectedAddressId] = React.useState(selectedAddressId || defaultAddressId);
  const [isAddressFormVisible, setIsAddressFormVisible] = React.useState(forceAddressFormVisibility);
  const [addressIdUnderEdit, setAddressIdUnderEdit] = React.useState<string | undefined>(undefined);
  const [shouldMarkAddressAsDefault, setShouldMarkAddressAsDefault] = React.useState(false);
  const biLogger = useBi();
  const { experiments } = useExperiments();

  const validateAddressAndRunSideEffects = React.useCallback(
    (addressToValidate: OloAddress | undefined) => {
      if (isMultiLocation && addressToValidate) {
        const partnerProps = //  temporary solution for SL new flow to work with delivery partner
          locations.length < 2 ? { shouldConsiderDeliveryPartner: true, deliveryPartnerFee: undefined } : undefined;
        const { hasLocations, locations: validLocations, reason } = validateAddressForLocations(
          locations,
          addressToValidate,
          partnerProps,
        );
        if (hasLocations && validLocations) {
          return;
        } else if (reason) {
          setAddressInputError({ validateAddressReason: reason });
          setErrorVisibility({ error: 'addressInput', value: true });
          return reason;
        }
      } else {
        const validateAddressReason = validateAddress({
          address: addressToValidate,
          restaurant,
          dispatchTime,
          totalOrderPrice,
          deliveryPartnerProps: {
            deliveryPartnerFee: undefined,
            shouldConsiderDeliveryPartner: true,
          },
        });

        if (validateAddressReason) {
          setErrorVisibility({ error: 'addressInput', value: true });
          setAddressInputError({ validateAddressReason });
        }

        return validateAddressReason;
      }
    },
    [restaurant, dispatchTime, totalOrderPrice, setAddressInputError, setErrorVisibility, isMultiLocation, locations],
  );

  const saveAddress = React.useCallback(
    (addressToSave: OloAddress, addressId?: string) => {
      const validateAddressReason = validateAddressAndRunSideEffects(addressToSave);
      let isSuccess = false;

      const isAptValid =
        Boolean(addressToSave?.addressLine2) || !experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery');

      if (
        isAptValid &&
        (!validateAddressReason ||
          validateAddressReason.type === 'minimum-price' ||
          validateAddressReason.type === 'unavailable')
      ) {
        saveAddressToServer({
          address: convertToOloAddressMembersAddress(addressToSave),
          addressId,
          setAsDefault: shouldMarkAddressAsDefault,
        });
        setDeliveryAddressFromForm();
        isSuccess = true;
      }

      biLogger.saveAddressSelectionModal({
        isDefault: shouldMarkAddressAsDefault,
        isSuccess,
        pageName: 'form',
      });

      if (!isAptValid) {
        setFieldError({ error: 'addressLine2', value: true });
        setErrorVisibility({ error: 'addressLine2', value: true });
      }
    },
    [
      saveAddressToServer,
      setDeliveryAddressFromForm,
      validateAddressAndRunSideEffects,
      shouldMarkAddressAsDefault,
      biLogger,
      setFieldError,
      setErrorVisibility,
      experiments,
    ],
  );

  const handleOkClick = React.useCallback(() => {
    const selectedAddress = addresses.find((da) => da.id === localSelectedAddressId);
    if (selectedAddress && !isAddressFormVisible) {
      const oloAddress = convertMembersAddressToOloAddress(selectedAddress);
      setDeliveryAddress({ address: oloAddress });
      setSelectedAddress({ address: oloAddress, shouldNotKeepFields: true });
      setSelectedAddressId({ id: selectedAddress.id });
      biLogger.saveAddressSelectionModal({
        isDefault: false,
        isSuccess: true,
        pageName: 'list',
      });
      onRequestClose();
    } else if (isAddressFormVisible && selectedAddressOption) {
      saveAddress(selectedAddressOption, addressIdUnderEdit);
    }
  }, [
    setDeliveryAddress,
    onRequestClose,
    addresses,
    localSelectedAddressId,
    isAddressFormVisible,
    selectedAddressOption,
    setSelectedAddress,
    saveAddress,
    addressIdUnderEdit,
    setSelectedAddressId,
    biLogger,
  ]);

  const handleCloseClick = React.useCallback(() => {
    if (isAddressFormVisible) {
      setIsAddressFormVisible(false);
      setAddressIdUnderEdit(undefined);
      initAddressForm();
    } else {
      onRequestClose();
    }
  }, [isAddressFormVisible, onRequestClose, initAddressForm]);

  const handleAddNewAddressClick = React.useCallback(() => {
    biLogger.addNewAddressSelectionModal({});
    initAddressForm();
    setIsAddressFormVisible(true);
  }, [initAddressForm, biLogger]);

  const footer = (
    <ModalLayoutBasicFooter
      spaceBetween
      secondaryButtonContent={'+ ' + t('checkout_main_deliverymethod_modal_chooseaddress.addnew.cta')}
      onSecondaryClick={handleAddNewAddressClick}
      onOkClick={handleOkClick}
      showSecondaryButton={!isAddressFormVisible}
      isLoading={isLoadingAddressesFromServer}
    />
  );

  const list = (
    <React.Fragment>
      {addresses.map((address) => {
        const error = getDisplayableAddressError({
          address: convertMembersAddressToOloAddress(address),
          restaurant,
          dispatchTime,
          totalOrderPrice,
          t,
          isAptRequired: experiments.enabled('specs.restaurants.AptFieldIsRequiredInDelivery'),
          isMultiLocation,
          locations,
        });

        return (
          address.id && (
            <React.Fragment key={address.id}>
              <div className={styles.optionWrapper} data-hook={dataHooks.addressSelectionModalOption}>
                <RadioButton
                  name="restaurants-address-selection-modal-option"
                  value={address.id}
                  className={styles.option}
                  checked={localSelectedAddressId === address.id}
                  onChange={() => setLocalSelectedAddressId(address.id)}
                  withFocusRing
                >
                  {address.type && <p>{address.type}</p>}
                  <p data-hook={dataHooks.addressSelectionModalOptionLabel}>{address.addressLine1}</p>
                  {address.addressLine2 && <p>{address.addressLine2}</p>}
                  {address.hint && <p>{address.hint}</p>}
                </RadioButton>

                <TextButton
                  priority={TEXT_BUTTON_PRIORITY.link}
                  data-hook={dataHooks.addressSelectionModalOptionEdit}
                  onClick={() => {
                    setSelectedAddress({ address: convertMembersAddressToOloAddress(address) });
                    setAddressIdUnderEdit(address.id);
                    setIsAddressFormVisible(true);
                    biLogger.editAddressSelectionModal({});
                  }}
                >
                  {t('cart_button_edit')}
                </TextButton>
              </div>

              {error && (
                <SectionNotification
                  type="error"
                  className={styles.error}
                  data-hook={`${dataHooks.addressSelectionModalOptionError}-${address.id}`}
                >
                  <SectionNotification.Icon icon={<Error />} />
                  <SectionNotification.Text>{error}</SectionNotification.Text>
                </SectionNotification>
              )}

              <div className={styles.divider} />
            </React.Fragment>
          )
        );
      })}
    </React.Fragment>
  );

  const form = (
    <React.Fragment>
      <AddressInformationDelivery
        restaurant={restaurant}
        dispatchTime={dispatchTime}
        totalOrderPrice={totalOrderPrice}
        onAddressInputBlur={validateAddressAndRunSideEffects}
        onAddressInputSelect={validateAddressAndRunSideEffects}
        showAddressLine2
        forceCommentInputVisibility
        locations={[]}
        shouldDisplayAllInputs
      />

      <Checkbox
        label={t('checkout_main_deliverymethod_modal.addaddress.default.checkbox.text')}
        onChange={() => setShouldMarkAddressAsDefault(!shouldMarkAddressAsDefault)}
        checked={shouldMarkAddressAsDefault}
        data-hook={dataHooks.addressSelectionModalSetAsDefault}
        name={dataHooks.addressSelectionModalSetAsDefault}
        className={styles.checkbox}
      />
    </React.Fragment>
  );

  return (
    <ModalLayoutSelection
      header={
        isAddressFormVisible
          ? t('checkout_main_deliverymethod_modal.addaddress.title')
          : t('checkout_main_deliverymethod_modal_chooseaddress.title')
      }
      onCloseClick={handleCloseClick}
      footer={footer}
      data-hook={dataHooks.addressSelectionModal}
      scrollable
    >
      <DrillDownComponent primary={list} secondary={form} isDrilled={isAddressFormVisible} />
    </ModalLayoutSelection>
  );
};

AddressSelectionModal.displayName = 'AddressSelectionModal';

export default translate()(AddressSelectionModal);

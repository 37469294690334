import React from 'react';
import Text from '../../../core-components/Text';
import styles from '../AddressInformation.scss';
import dataHooks from '../../../data-hooks';
import { TranslationFunction } from 'i18next';
import ChooseLocationDropdown from '../../ChooseLocationDropdown';
import classNames from 'classnames';
import { useAppSelector } from '../../../../../core/hooks/redux';
import { DeliveryFormField } from '../../../../../state/addressInformationForm/addressForm.reducer';

interface AddressInformationTakeoutProps {
  formattedAddressWithComment: string;
  t: TranslationFunction;
  onLocationChange?: (id: string) => void;
  formErrorsVisiblity: Record<DeliveryFormField, boolean>;
  selectedLocation?: string;
  className: boolean;
  appendToElement?: Element;
}

const RestaurantTakeoutDetails: React.FC<AddressInformationTakeoutProps> = ({
  formattedAddressWithComment,
  t,
  onLocationChange = () => {},
  formErrorsVisiblity,
  selectedLocation,
  className,
  appendToElement,
}) => {
  const isMultiLocation = useAppSelector((state) => state.session.isMultiLocation);

  if (isMultiLocation) {
    return (
      <div
        className={classNames({
          [styles.restaurantAddress]: true,
          [styles.restaurantAddressMin]: className,
        })}
      >
        <ChooseLocationDropdown
          appendToElement={appendToElement}
          labelText={t('order_settings_modal_pickup_location_label')}
          filters="takeout"
          location={selectedLocation}
          onChange={onLocationChange}
          error={formErrorsVisiblity.location}
        />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Text
          typography="p2-s"
          className={styles.restaurantAddressLabel}
          data-hook={dataHooks.addressInformationTakeoutTitle}
        >
          {t('order_settings_modal_pickup_label')}
        </Text>
        <Text
          typography="p2-m"
          data-hook={dataHooks.addressInformationTakeoutAddress}
          className={classNames({
            [styles.restaurantAddress]: true,
            [styles.restaurantAddressMin]: className,
          })}
        >
          {formattedAddressWithComment}
        </Text>
      </React.Fragment>
    );
  }
};

RestaurantTakeoutDetails.displayName = 'RestaurantTakeoutDetails';

export default RestaurantTakeoutDetails;

import UpgradeToPremiumModal from './UpgradeToPremiumModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ...ownProps: any) {
  return {
    instanceId: state.platformParams.instance.instanceId,
    appDefId: state.platformParams.instance.appDefId,
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch);
}
export default translate()(connect(mapStateToProps, mapDispatchToProps)(UpgradeToPremiumModal));

import React from 'react';
import styles from './Main.scss';
import ModalManager from '../ModalManager';
import Routes from '../Routes';

export interface MainProps {
  isRTL: boolean;
  compId: string;
}

const Main: React.FC<MainProps> = React.memo(({ isRTL, compId }) => {
  React.useEffect(() => {
    const selector = `.${compId}`;
    const elem = document.querySelector(selector);
    if (elem) {
      elem.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    }
  }, [isRTL, compId]);

  return (
    <div id="modal-wrapper">
      <div id="main-page" className={styles.wrapper} data-hook="MainPage-wrapper" dir={isRTL ? 'rtl' : 'ltr'}>
        <Routes />
      </div>
      <ModalManager />
    </div>
  );
});

Main.displayName = 'Main';

export default Main;

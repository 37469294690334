import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import styles from './Sticky.scss';
import { calculateTopValue } from '../Scroller/scrollingUtils';

export interface StickyProps {
  className?: string;
  offset?: number;
  onChange?: (isSticky: boolean) => void;
}

const Sticky: React.FC<StickyProps> = ({ className, offset = 0, children, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(0);
  const [isAtTopOfViewPort, setAtTopOfViewPort] = useState(false);

  useEffect(() => {
    setImmediate(() => {
      setTop(calculateTopValue(ref.current?.offsetLeft || 0));
    });

    const onScroll = () => {
      setTop(calculateTopValue(ref.current?.offsetLeft || 0));

      const rectTop = ref.current?.getBoundingClientRect().top;

      if (onChange && rectTop !== undefined) {
        const newAtTopOfViewPort = rectTop - top <= 0;

        if (newAtTopOfViewPort !== isAtTopOfViewPort) {
          setAtTopOfViewPort(newAtTopOfViewPort);
          onChange(newAtTopOfViewPort);
        }
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', _.throttle(onScroll, 50));
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [ref, onChange, top, isAtTopOfViewPort]);

  return (
    <div ref={ref} className={`${styles.wrapper} ${className}`} style={{ top: top + offset }}>
      {children}
    </div>
  );
};

Sticky.displayName = 'Sticky';

export default Sticky;

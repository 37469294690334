import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import DispatchTimeSelector from './DispatchTimeSelector';
import { AppState } from '../../../../state/createStore';
import { setErrorVisibility } from '../../../../state/addressInformationForm/addressForm.actions';
import { selectLocations } from '../../../../state/selectors/locationsSelector';
import { dtlv2_getSupportedDispatchTypes } from '@wix/restaurants-client-logic';

function mapStateToProps(state: AppState, ownProps: any) {
  return {
    isMl: state.session.isMultiLocation,
    address: ownProps.address || state.addressForm.selectedAddressOption,
    supportedDispatchTypesV2: dtlv2_getSupportedDispatchTypes({ restaurants: selectLocations(state) }),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setErrorVisibility,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchTimeSelector);

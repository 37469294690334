import React from 'react';
import { useHistory } from 'react-router-dom';
import { CloseModalPayload, NavigatePayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import {
  AddOrderCouponPayload,
  ChangeOrderItemCountPayload,
  RemoveOrderItemPayload,
  SetOrderCommentPayload,
} from '../../../../state/cart/cart.actions.types';
import CartCoupon from '../CartCoupon';
import styles from './Cart.scss';
import dataHooks from '../../data-hooks';
import CartDiscount from '../CartDiscount';
import CartSubtotal from '../CartSubtotal';
import CartOrderNotes from '../CartOrderNotes';
import CartDishItem from '../CartDishItem';
import Text from '../../core-components/Text';
import CartTitle from '../CartTitle';
import { useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import {
  VirtualDispatchType,
  DisplayableDiscount,
  DisplayableOrderItem,
  DisplayablePriceComponents,
  Restaurant,
} from '@wix/restaurants-client-logic';
import { getDiscountDescription, getDisplayableDiscountCondition, getErrorString } from './Cart.helper';
import { TranslationFunction } from 'i18next';
import { Modals } from '../../../../core/constants';
import {
  ResetLoyaltyPointsToDefaultValuePayload,
  SetLoyaltyPointsToRedeemPayload,
} from '../../../../state/checkout/checkout.actions.types';
import { DisplayableReward } from '../CartDiscount/CartDiscount';
import CartLoyaltyGuest from '../CartLoyaltyGuest/CartLoyaltyGuest';
import CartLoyaltyButton from '../CartLoyaltyButton';
import CartLoyaltyAppendix from '../CartLoyaltyAppendix';
import { EarningRule } from '@wix/ambassador-loyalty-calculator/types';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';

export interface CartProps {
  comment?: string;
  navigate: (payload: NavigatePayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
  openModal: (payload: OpenModalPayload) => void;
  setOrderComment: (payload: SetOrderCommentPayload) => void;
  addOrderCoupon: (payload: AddOrderCouponPayload) => void;
  removeOrderCoupon: (payload: void) => void;
  displayableDiscounts: DisplayableDiscount[];
  displayablePriceComponents: DisplayablePriceComponents;
  displayableOrderItems: DisplayableOrderItem[];
  couponCode?: string;
  couponError?: string;
  removeOrderItem: (payload: RemoveOrderItemPayload) => void;
  changeOrderItemCount: (payload: ChangeOrderItemCountPayload) => void;
  displayTitle?: boolean;
  hideTotal?: boolean;
  shortVersion?: boolean;
  disabled?: boolean;
  hasCoupons: boolean;
  isCartImmutable?: boolean;
  dispatchType: VirtualDispatchType;
  t: TranslationFunction;
  hideMinOrderPrice?: boolean;
  className?: string;
  isMobile: boolean;
  isRTL: boolean;
  restaurant: Restaurant;
  displayableRewards: DisplayableReward[];
  redeemPoints?: number;
  setLoyaltyPointsToRedeem: (payload: SetLoyaltyPointsToRedeemPayload) => void;
  loyaltyDiscountIconUrl?: string;
  resetLoyaltyPointsToDefaultValue: (payload: ResetLoyaltyPointsToDefaultValuePayload) => void;
  shouldDisplayLoyaltyButton?: boolean;
  isUserLoggedIn?: boolean;
  customPointsName?: string;
  loginMember?: any;
  pointsRequired?: number;
  currencyValue?: number;
  centsAmount?: number;
  pointsBalance?: number;
  isLoyaltyEarnActive?: boolean;
  loyaltyProgramName?: string;
  loyaltyRewardId?: string;
  pointsToBeEarned?: string;
  earningRules: EarningRule[];
}

const Cart: React.FC<CartProps> = ({
  comment,
  setOrderComment,
  addOrderCoupon,
  removeOrderCoupon,
  displayableDiscounts,
  displayablePriceComponents,
  displayableOrderItems,
  couponCode,
  couponError,
  removeOrderItem,
  changeOrderItemCount,
  displayTitle,
  hideTotal,
  shortVersion,
  disabled,
  hasCoupons,
  isCartImmutable,
  dispatchType,
  t,
  hideMinOrderPrice,
  className,
  openModal,
  isMobile,
  isRTL,
  restaurant,
  displayableRewards,
  redeemPoints,
  setLoyaltyPointsToRedeem,
  loyaltyDiscountIconUrl,
  resetLoyaltyPointsToDefaultValue,
  shouldDisplayLoyaltyButton,
  isUserLoggedIn,
  customPointsName,
  loginMember,
  pointsRequired = 0,
  currencyValue = 0,
  centsAmount,
  isLoyaltyEarnActive,
  pointsBalance,
  loyaltyProgramName,
  loyaltyRewardId,
  pointsToBeEarned,
  earningRules,
}) => {
  const { deliveryFee, subtotal, tax, tip, total } = displayablePriceComponents;
  const isCartEmpty = displayableOrderItems.length === 0;
  const history = useHistory();
  const biLogger = useBi();
  const { displayableMinOrderPrice } = useMinOrderPriceDetails();

  const sendBiAmountChanged = (currentAmount: number, newAmount: number, orderItemId: string) => {
    const action = newAmount > currentAmount ? 'increase' : 'decrease';
    biLogger.cartDishQuantitiesUpdate({
      action,
      dishId: orderItemId,
      quantity: newAmount,
    });
  };

  const experiments = useExperiments();
  const isDiscountIconV2Enabled = experiments.experiments.enabled('specs.restaurants.olo-client-discount-icon-v2');

  return (
    <div className={`${styles.wrapper} ${className}`} data-hook="cart">
      {displayTitle && !isCartEmpty && (
        <CartTitle
          className={styles.title}
          typography="header-xs-responsive"
          displayViewOrderButton={shortVersion}
          onViewOrderButtonClick={() => openModal({ modal: Modals.CART_MODAL_CHECKOUT })}
        />
      )}

      {isCartEmpty && (
        <div className={styles.emptyState} data-hook={dataHooks.cartEmpty}>
          <Text typography="p2-s-secondary">{t('cart_emptystate_additems_text')}</Text>
        </div>
      )}

      {!shortVersion &&
        displayableOrderItems.map((orderItem, itemIndex) => (
          <CartDishItem
            key={dataHooks.cartDishItem(itemIndex)}
            hook={dataHooks.cartDishItem(itemIndex)}
            title={orderItem.title}
            count={orderItem.quantity}
            price={orderItem.totalPrice}
            comment={orderItem.specialRequest}
            error={getErrorString(orderItem, dispatchType, t, isMobile, orderItem.isDishDeleted)}
            options={orderItem.variationsChoicesStrings}
            isCartImmutable={isCartImmutable}
            onAmountChange={(count: number) => {
              sendBiAmountChanged(orderItem.quantity, count, orderItem.id);
              count === 0 ? removeOrderItem({ itemIndex }) : changeOrderItemCount({ count, itemIndex });
            }}
            onEdit={() => {
              if (!isCartImmutable) {
                history.push(`/dish/${itemIndex}/edit`);
                biLogger.openDishModal({ dishId: orderItem.id, origin: 'cart' });
              }
            }}
            onRemove={() => {
              biLogger.cartRemoveDish({
                dishId: orderItem.id,
              });
              removeOrderItem({ itemIndex });
            }}
            isLast={itemIndex === displayableOrderItems.length - 1}
          />
        ))}

      {!isCartEmpty && displayableDiscounts.length > 0 && (
        <div data-hook={dataHooks.cartDiscounts} className={styles.cartDiscounts}>
          {displayableDiscounts.map((dd, index) => {
            const key = `cart-discount-${index}`;
            const iconUrl = dd.isLoyalty ? loyaltyDiscountIconUrl : undefined;
            const title = dd.isLoyalty ? loyaltyProgramName : dd.title;
            const description = getDiscountDescription(
              dd,
              t,
              pointsRequired,
              currencyValue,
              restaurant,
              customPointsName,
            );
            const origin = isCartImmutable ? 'checkout' : 'cart';
            const onPointsChange = (loyaltyPointsToRedeem: number, isRemoved: boolean = false) => {
              setLoyaltyPointsToRedeem({ loyaltyPointsToRedeem });
              if (isRemoved) {
                biLogger.removeLoyaltyReward({ origin, rewardId: loyaltyRewardId });
              } else {
                biLogger.loyaltyEditPointsChangeValue({
                  origin,
                  rewardId: loyaltyRewardId,
                  loyaltyPoints: loyaltyPointsToRedeem,
                }); // evid 870
              }
            };
            const onRemove = dd.isCoupon
              ? () => removeOrderCoupon()
              : dd.isLoyalty
              ? () => onPointsChange(0, true)
              : undefined;
            const onLoyaltySaveCTAClick = () => {
              biLogger.loyaltyEditPointsSave({
                loyaltyPoints: redeemPoints,
                origin,
                rewardId: loyaltyRewardId,
              });
            };
            const onLoyaltyEditCTAClick = () => {
              biLogger.loyaltyEditPoints({
                origin,
                rewardId: loyaltyRewardId,
                numOfOptions: displayableRewards.length,
                loyaltyPoints: redeemPoints,
              });
            };

            return (
              <CartDiscount
                onRemove={onRemove}
                index={index}
                key={key}
                title={title}
                amount={dd.amount}
                description={description}
                condition={getDisplayableDiscountCondition(dd, t)}
                loyalty={dd.isLoyalty}
                customPointsName={customPointsName}
                pointsRequired={pointsRequired}
                pointsBalance={pointsBalance}
                currencyValue={currencyValue}
                displayableRewards={displayableRewards}
                selectedReward={redeemPoints}
                onPointsChange={onPointsChange}
                iconUrl={iconUrl}
                useNewIcon={isDiscountIconV2Enabled}
                onLoyaltySaveCTAClick={onLoyaltySaveCTAClick}
                onLoyaltyEditCTAClick={onLoyaltyEditCTAClick}
              />
            );
          })}
        </div>
      )}

      {isLoyaltyEarnActive && !isUserLoggedIn && !shortVersion && displayableOrderItems.length > 0 && (
        <CartLoyaltyGuest
          subtotal={subtotal}
          iconUrl={loyaltyDiscountIconUrl}
          customPointsName={customPointsName}
          loginMember={loginMember}
          centsAmount={centsAmount}
          pointsRequired={pointsRequired}
          currencyValue={currencyValue}
          useNewIcon={isDiscountIconV2Enabled}
          earningRules={earningRules}
        />
      )}

      {shouldDisplayLoyaltyButton && (
        <CartLoyaltyButton
          disabled={disabled}
          onClick={() => {
            resetLoyaltyPointsToDefaultValue({ origin: isCartImmutable ? 'checkout' : 'cart' });
          }}
        />
      )}

      {hasCoupons && !couponCode && (
        <CartCoupon
          addOrderCoupon={addOrderCoupon}
          removeOrderCoupon={removeOrderCoupon}
          error={couponError}
          disabled={disabled}
          biLogger={biLogger}
          isMobile={isMobile}
          isRTL={isRTL}
        />
      )}

      <CartOrderNotes
        value={comment || ''}
        onChange={(newComment: string) => setOrderComment({ comment: newComment })}
        disabled={disabled}
      />

      <CartSubtotal
        deliveryFee={deliveryFee}
        minOrderPrice={hideMinOrderPrice ? undefined : displayableMinOrderPrice}
        isEstimatedDeliveryFee={displayablePriceComponents.isEstimatedDeliveryFee}
        showTotal={!hideTotal}
        subtotal={subtotal}
        taxes={tax}
        tip={tip}
        total={total}
      />

      {isLoyaltyEarnActive && isUserLoggedIn && isCartImmutable && (
        <CartLoyaltyAppendix customPointsName={customPointsName} pointsToBeEarned={pointsToBeEarned} />
      )}
    </div>
  );
};

export default Cart;

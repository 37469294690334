import DispatchSummary from './DispatchSummary';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { deliveryAreaSelectorByDispatchAddress } from '../../../../state/selectors/deliveryAreaSelector';
import { getDineInInfo, getVirtualDispatchTypeFromDispatch } from '../../../../core/logic/dispatchLogic';

function mapStateToProps(state: AppState) {
  const { restaurant } = state.session;
  const idealDeliveryArea = deliveryAreaSelectorByDispatchAddress(state);
  const dineInInfo = getDineInInfo(state.session.restaurant);

  return {
    dispatchType: getVirtualDispatchTypeFromDispatch(state.checkout.dispatch),
    dispatchTime: state.checkout.dispatch.time,
    address: state.checkout.dispatch.type === 'delivery' ? state.checkout.dispatch.address : undefined,
    isCurbside: state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.isCurbside : undefined,
    timezone: restaurant.timezone,
    tableLabel: dineInInfo ? dineInInfo.label : '',
    tableNumber:
      state.checkout.dispatch.type === 'takeout' ? state.checkout.dispatch.contactlessDineIn?.labelValue : undefined,
    deliveryInfos: restaurant.deliveryInfos,
    idealDeliveryArea: idealDeliveryArea?.dispatchInfo,
    disabled:
      state.session.restaurant.address.formatted === '' || state.session.restaurant.address.formatted === '[noskip]',
    locale: state.session.restaurant.locale,
    isLocationPicked: state.session.isLocationPicked,
  };
}

export default connect(mapStateToProps)(DispatchSummary);

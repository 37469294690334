import React from 'react';
import ModalLayout from '../ModalLayout';
import Text from '../../core-components/Text';
import { PRIORITY, SIZE } from 'wix-ui-tpa/Button';
import dataHooks from '../../data-hooks';
import { Policy } from '@wix/restaurants-client-logic';
import styles from './PrivacyPolicyModal.scss';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';
import Button from '../Button';

export interface PrivacyPolicyModalProps {
  orderPolicy?: Policy;
  onRequestClose: () => void;
  t: TranslationFunction;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ onRequestClose, orderPolicy, t }) => {
  const footer = (
    <div className={styles.wrapperCloseBtn}>
      <Button
        upgrade
        fullWidth
        data-hook={dataHooks.privacyPolicyModalOkButton}
        onClick={() => {
          onRequestClose();
        }}
        className={styles.footer}
        priority={PRIORITY.primary}
        size={SIZE.large}
      >
        <Text typography="p2-m-colorless">{t('ordermodal_privacypolicy_close_CTA')}</Text>
      </Button>
    </div>
  );

  return (
    <ModalLayout
      header={t('ordermodal_privacypolicy_title')}
      footer={footer}
      onCloseClick={() => onRequestClose()}
      data-hook={dataHooks.privacyPolicyModal}
    >
      <Text typography="p2-m">{orderPolicy?.privacyPolicy?.value}</Text>
    </ModalLayout>
  );
};

PrivacyPolicyModal.displayName = 'PrivacyPolicyModal';

export default translate()(PrivacyPolicyModal);

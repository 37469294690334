import { useState, useEffect } from 'react';
import { PaymentMethod as PaymentMethodType } from '@wix/cashier-payments-widget/dist/src/cashier-payments';

interface Icon {
  variant: 'RESIZABLE_PNG' | 'SVG';
  src: string;
}

export default (locale: string, isCashier: boolean, paymentMethod?: string): string | undefined => {
  const [paymentIcon, setPaymentIcon] = useState();

  useEffect(() => {
    let isMounted = true;
    const fetchPaymentProviders = async () => {
      const localeSplit = locale.split('_');
      const data = await fetch(
        `https://apps.wixrestaurants.com/wix/_api/payment-metadata-web/plugins/data?country=${localeSplit[1]}&locale=${localeSplit[0]}`,
      );
      const { methods } = await data.json();
      const method = methods.find(({ id }: { id: PaymentMethodType }) => paymentMethod === id);
      const icon = method?.icons.find((currIcon: Icon) => currIcon.variant === 'RESIZABLE_PNG');

      if (icon && isMounted) {
        try {
          setPaymentIcon(icon.src.replace('{width}', '42').replace('{height}', '25'));
        } catch (e) {
          console.warn(e);
        }
      }
    };

    if (isCashier) {
      fetchPaymentProviders();
      return () => {
        isMounted = false;
      };
    }
  }, [locale, isCashier, paymentMethod]);

  return paymentIcon;
};

import InternalErrorModal from './InternalErrorModal';
import { AppState } from '../../../../state/createStore';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setErrorType } from '../../../../state/checkout/checkout.actions';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState, ...ownProps: any) {
  return {
    ...ownProps,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resetErrors: () => setErrorType({ errorType: undefined, errorCode: undefined, errorChargeId: undefined }),
    },
    dispatch,
  );
}
export default translate()(connect(mapStateToProps, mapDispatchToProps)(InternalErrorModal));

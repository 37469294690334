import React from 'react';
import styles from './PriceView.scss';
import dataHooks from '../../data-hooks';
import Text, { TextProps } from '../../core-components/Text';

export type PriceViewProps = {
  price?: string;
  minPrice?: string;
  maxPrice?: string;
  currency: string;
  className?: string;
  'data-hook'?: string;
  suffix?: React.ReactNode;
  withPlusSign?: boolean;
} & Pick<TextProps, 'typography'>;

function formatPriceStr(price: string, currency?: string) {
  if (!currency) {
    return price;
  }

  const numeric = price.match(/[\d.,]+/g)?.[0] || '';

  return numeric + ' ' + currency;
}

function getPriceStr(price?: string, minPrice?: string, maxPrice?: string, currency?: string) {
  if (minPrice && maxPrice && minPrice !== maxPrice) {
    return `${formatPriceStr(minPrice, currency)} - ${formatPriceStr(maxPrice, currency)}`;
  } else if (price || minPrice) {
    return formatPriceStr(price || minPrice || '', currency);
  } else {
    return '';
  }
}

const PriceView: React.FC<PriceViewProps> = ({
  price,
  minPrice,
  maxPrice,
  className,
  typography,
  suffix,
  currency,
  withPlusSign,
}) => {
  if (!price && !minPrice && !maxPrice && !suffix) {
    // nothing to display
    return null;
  }

  return (
    <Text typography={typography} className={className} data-hook={dataHooks.itemViewPrice}>
      <span aria-hidden>
        {/* using aria-hidden, so screen readers will not read this value */}
        {(withPlusSign ? '+' : '') + getPriceStr(price, minPrice, maxPrice)}
      </span>
      <span className={styles.srOnly}>
        {/* using the srOnly class, so seeing users will not see it */}
        {getPriceStr(price, minPrice, maxPrice, currency)}
      </span>
      {suffix && <span> {suffix}</span>}
    </Text>
  );
};

PriceView.displayName = 'PriceView';

export default PriceView;

import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import styles from '../OrderFailureModal/OrderFailureModal.scss';
import Button from '../Button';
import dataHooks from '../../data-hooks';
import { TranslationFunction } from 'i18next';

export interface InternalErrorModalProps {
  onRequestClose: () => void;
  resetErrors: () => void;
  t: TranslationFunction;
}

const InternalErrorModal: React.FC<InternalErrorModalProps> = ({ onRequestClose, resetErrors, t }) => {
  const onCloseModal = () => {
    onRequestClose();
    resetErrors();
  };

  const text = t('checkout_deliveryintegration_errormsg_volume_cta');
  const details = [t('checkout_deliveryintegration_errormsg_volume_text')];

  const onClick = () => {
    onCloseModal();
  };

  return (
    <ModalLayoutBasic header={null} onCloseClick={onClick} data-hook={dataHooks.estimateTPAFailureModal}>
      <div className={styles.wrapper}>
        <div data-hook={dataHooks.estimateTPAFailureModalSubtitle}>
          {details.map((line) => (
            <Text typography="p2-m" tagName="p" key={line}>
              {line}
            </Text>
          ))}
        </div>
        <Button
          className={styles.okButton}
          upgrade
          data-hook={dataHooks.estimateTPAFailureModalOkButton}
          onClick={onClick}
        >
          {text}
        </Button>
      </div>
    </ModalLayoutBasic>
  );
};

InternalErrorModal.displayName = 'InternalErrorModal';

export default InternalErrorModal;

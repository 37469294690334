import React from 'react';
import { Checkbox as TpaCheckbox, CheckboxProps as TpaCheckboxProps } from 'wix-ui-tpa/Checkbox';
import { st, classes } from './Checkbox.st.css';

export interface CheckboxProps extends TpaCheckboxProps {}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  return <TpaCheckbox {...props} className={st(classes.root, {}, props.className)} />;
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;

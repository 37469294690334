import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import { cartSummarySelector } from './cartSummarySelector';
import {
  Address,
  Dispatch,
  PriceComponents,
  Restaurant,
  getIdealDeliveryArea as idealDeliveryArea,
} from '@wix/restaurants-client-logic';

export interface getIdealDeliveryArea {
  address?: Address;
  dispatch: Dispatch;
  priceComponents: PriceComponents;
  restaurant: Restaurant;
}

const getIdealDeliveryArea = ({ address, dispatch, priceComponents, restaurant }: getIdealDeliveryArea) => {
  if (dispatch.type !== 'delivery') {
    return undefined;
  }

  return idealDeliveryArea({
    address: address || dispatch.address,
    dispatchTime: dispatch.time,
    totalOrderPrice: priceComponents.total,
    restaurant,
    deliveryPartnerProps: {
      deliveryPartnerFee: undefined,
      shouldConsiderDeliveryPartner: true,
    },
  });
};

export const deliveryAreaSelectorByDispatchAddress = createSelector(
  (state: AppState) => state.checkout.dispatch,
  (state: AppState) => state.session.restaurant,
  cartSummarySelector,
  (dispatch, restaurant, { priceComponents }) => {
    return getIdealDeliveryArea({ dispatch, restaurant, priceComponents });
  },
);

export const deliveryAreaSelectorByAddressForm = createSelector(
  (state: AppState) => state.addressForm.selectedAddressOption,
  (state: AppState) => state.checkout.dispatch,
  (state: AppState) => state.session.restaurant,
  cartSummarySelector,
  (selectedAddressOption, dispatch, restaurant, { priceComponents }) => {
    return getIdealDeliveryArea({ address: selectedAddressOption, dispatch, restaurant, priceComponents });
  },
);

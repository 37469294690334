import { Dispatch, VirtualDispatchType } from '@wix/restaurants-client-logic';
import {
  DispatchInfo,
  PickupDispatchInfo,
  Restaurant,
} from '@wix/restaurants-client-logic/dist/types/types/Restaurant';
import StatusHelper from '@wix/wixrest-utils/dist/openrest-helpers/Status';
import moment from 'moment';
import { PartialLocation } from '../oloApi';

export const getVirtualDispatchTypeFromDispatch = (dispatch: Dispatch): VirtualDispatchType => {
  return dispatch.type !== 'takeout' ? dispatch.type : dispatch.contactlessDineIn ? 'dine-in' : 'takeout';
};

export const getDispatchTypeFromVirtual = (dispatchType: VirtualDispatchType) =>
  dispatchType !== 'dine-in' ? dispatchType : 'takeout';

export const getDineInInfo = (restaurant: Restaurant | PartialLocation) => {
  const takeout = restaurant.deliveryInfos.find((info) => info.type === 'takeout');
  return takeout && (takeout as PickupDispatchInfo).contactlessDineInInfo!;
};

export const isPickupUnavailableATM = (deliveryInfo: DispatchInfo[], restaurant: Restaurant) => {
  const takeout = deliveryInfo.find((info) => info.type === 'takeout');
  return takeout
    ? StatusHelper.getStatus(takeout.availability, moment().tz(restaurant.timezone)).status === 'unavailable' ||
        takeout.inactive ||
        restaurant.orders.asap.disabled
    : false;
};

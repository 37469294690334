import React from 'react';
import ModalLayoutBasic from '../ModalLayoutBasic';
import Text from '../../core-components/Text';
import dataHooks from '../../data-hooks';
import { Policy } from '@wix/restaurants-client-logic';
import Button from '../Button';
import styles from './TermsAndConditionsModal.scss';
import { translate } from 'yoshi-flow-editor-runtime';
import { TranslationFunction } from 'i18next';

export interface TermsAndConditionsModalProps {
  orderPolicy?: Policy;
  onRequestClose: () => void;
  t: TranslationFunction;
}

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({ onRequestClose, orderPolicy, t }) => {
  const footer = (
    <Button
      upgrade
      fullWidth
      data-hook={dataHooks.termsAndConditionsModalOkButton}
      onClick={() => {
        onRequestClose();
      }}
      className={styles.footer}
    >
      {t('ordermodal_termsconditions_close_CTA')}
    </Button>
  );

  return (
    <ModalLayoutBasic
      header={t('ordermodal_termsconditions_title')}
      footer={footer}
      onCloseClick={() => onRequestClose()}
      data-hook={dataHooks.termsAndConditionsModal}
    >
      <Text typography="p2-m">{orderPolicy?.termsAndConditions?.value}</Text>
    </ModalLayoutBasic>
  );
};

TermsAndConditionsModal.displayName = 'TermsAndConditionsModal';

export default translate()(TermsAndConditionsModal);

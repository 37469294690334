import LabelsView from './LabelsView';
import { connect } from 'react-redux';
import { AppState } from '../../../../state/createStore';
import { translate } from 'yoshi-flow-editor-runtime';

function mapStateToProps(state: AppState) {
  return {
    labelsSettings: state.platformParams.labelsSettings,
  };
}

export default translate()(connect(mapStateToProps)(LabelsView));

import React from 'react';
import { Tip, TipType } from '@wix/restaurants-client-logic';
import CurrencyUtils from '@wix/wixrest-utils/dist/CurrencyUtils';
import { SetTipPayload } from '../../../../state/checkout/checkout.actions.types';
import dataHooks from '../../data-hooks';
import CurrencyInput from '../../core-components/CurrencyInput/CurrencyInput';
import Text from '../../core-components/Text/Text';
import styles from './TipPicker.scss';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';

interface TipPickerProps {
  tip?: Tip;
  currency: string;
  locale: string;
  onTipChange: (payload: SetTipPayload) => void;
  t: TranslationFunction;
}

const TipPicker = ({ tip, onTipChange, currency, locale, t }: TipPickerProps) => {
  const [isCustomAmount, setIsCustomAmount] = React.useState(
    !!tip && (tip.tipType !== TipType.TipTypePercent || tip.amount > 15 || tip.amount % 5 !== 0),
  );
  const [isCustomAmountPercentage, setIsCustomAmountPercentage] = React.useState(
    tip ? tip.tipType === TipType.TipTypePercent : true,
  );
  const currencySymbol = CurrencyUtils.currencySymbol(currency);

  return (
    <div className={styles.wrapper} data-hook={dataHooks.tipPicker}>
      <Text typography="p2-l">{t('cart_tip_leavetip_label')}</Text>
      <div className={styles.presetBoxes}>
        <button
          className={!isCustomAmount && (!tip || !tip.amount) ? styles.selected : ''}
          onClick={() => {
            setIsCustomAmount(false);
            onTipChange({ tip: { amount: 0, tipType: TipType.TipTypePercent } });
          }}
          data-hook={dataHooks.tipPickerNone}
        >
          <Text typography="p2-m">{t('cart_tip_option_none')}</Text>
        </button>
        <button
          className={!isCustomAmount && tip?.amount === 5 ? styles.selected : ''}
          onClick={() => {
            setIsCustomAmount(false);
            onTipChange({ tip: { amount: 5, tipType: TipType.TipTypePercent } });
          }}
          data-hook={dataHooks.tipPickerFivePercent}
        >
          <Text typography="p2-m">5%</Text>
        </button>
        <button
          className={!isCustomAmount && tip?.amount === 10 ? styles.selected : ''}
          onClick={() => {
            setIsCustomAmount(false);
            onTipChange({ tip: { amount: 10, tipType: TipType.TipTypePercent } });
          }}
          data-hook={dataHooks.tipPickerTenPercent}
        >
          <Text typography="p2-m">10%</Text>
        </button>
        <button
          className={!isCustomAmount && tip?.amount === 15 ? styles.selected : ''}
          onClick={() => {
            setIsCustomAmount(false);
            onTipChange({ tip: { amount: 15, tipType: TipType.TipTypePercent } });
          }}
          data-hook={dataHooks.tipPickerFifteenPercent}
        >
          <Text typography="p2-m">15%</Text>
        </button>
        <button
          className={isCustomAmount ? styles.selected : ''}
          onClick={() => {
            setIsCustomAmount(true);
            setIsCustomAmountPercentage(true);
          }}
          data-hook={dataHooks.tipPickerOther}
        >
          <Text typography="p2-m">{t('cart_tip_option_other')}</Text>
        </button>
      </div>
      {isCustomAmount && (
        <div className={styles.customAmount}>
          <CurrencyInput
            className={styles.customAmountInput}
            value={tip?.tipType === TipType.TipTypePercent ? tip.amount * 100 : tip?.amount || 0}
            onChange={(value: number) => {
              onTipChange({
                tip: {
                  amount: tip?.tipType === TipType.TipTypeCurrencyAmount ? value * 100 : value,
                  tipType: isCustomAmountPercentage ? TipType.TipTypePercent : TipType.TipTypeCurrencyAmount,
                },
              });
            }}
            dataHook={dataHooks.tipPickerCurrencyInput}
            locale={locale}
          />
          <button
            className={isCustomAmountPercentage ? styles.selected : ''}
            onClick={() => {
              onTipChange({
                tip: {
                  amount: tip?.tipType === TipType.TipTypeCurrencyAmount ? tip.amount / 100 : tip?.amount || 0,
                  tipType: TipType.TipTypePercent,
                },
              });
              setIsCustomAmountPercentage(true);
            }}
            data-hook={dataHooks.tipPickerPercentageButton}
          >
            %
          </button>
          <button
            className={!isCustomAmountPercentage ? styles.selected : ''}
            onClick={() => {
              setIsCustomAmountPercentage(false);
              onTipChange({
                tip: {
                  amount: tip?.tipType === TipType.TipTypePercent ? tip.amount * 100 : tip?.amount || 0,
                  tipType: TipType.TipTypeCurrencyAmount,
                },
              });
            }}
            data-hook={dataHooks.tipPickerCurrencyButton}
          >
            {currencySymbol}
          </button>
        </div>
      )}
    </div>
  );
};

export default translate()(TipPicker);

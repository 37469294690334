import React from 'react';
import TextField from '../TextField';
import dataHooks from '../../data-hooks';
import { AddOrderCouponPayload } from '../../../../state/cart/cart.actions.types';
import styles from './CartCoupon.scss';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import Text from '../../core-components/Text';
import { TranslationFunction } from 'i18next';
import { translate } from 'yoshi-flow-editor-runtime';
import Button from '../Button';

export interface CartCouponProps {
  error?: string;
  addOrderCoupon: (payload: AddOrderCouponPayload) => void;
  removeOrderCoupon: (payload: void) => void;
  forceInputVisibility?: boolean;
  forceInputValue?: string;
  disabled?: boolean;
  t: TranslationFunction;
  biLogger?: any;
  isMobile: boolean;
  isRTL?: boolean;
}

interface CartCouponState {
  couponText: string;
  isExpanded: boolean;
}

const MaxLength = 20;

class CartCoupon extends React.Component<CartCouponProps, CartCouponState> {
  inputRef: any;
  focusOnError: boolean = false;
  state = { couponText: '', isExpanded: false };

  componentDidUpdate(prevProps: CartCouponProps, prevState: CartCouponState) {
    if (this.props.error && this.focusOnError) {
      this.inputRef && this.inputRef.focus();
      this.focusOnError = false;
    }
  }

  handleBlur = () => {
    if (!this.getTextFieldValue()) {
      this.props.removeOrderCoupon();
    }
  };

  getTextFieldValue = () => {
    return this.props.forceInputValue || this.state.couponText;
  };

  handleCouponCodeChange = (e: { target: { value: string } }) => {
    const couponText = e.target.value
      .substr(0, MaxLength)
      .replace(/[^0-9a-z\-_]/gi, '')
      .toUpperCase();
    this.setState({ couponText });
  };

  handleClickOnAddCoupon = () => {
    this.props.biLogger && this.props.biLogger.cartAddPromoCode({});
    this.setState({ isExpanded: true });
  };

  render() {
    const { couponText, isExpanded } = this.state;
    const { addOrderCoupon, disabled, error, forceInputVisibility, t, isMobile, isRTL } = this.props;
    const errorTooltipPlacement = isMobile ? 'top' : isRTL ? 'top-start' : 'top-end';

    return (
      <div className={styles.wrapper}>
        {isExpanded || forceInputVisibility ? (
          <form
            data-hook={dataHooks.cartPromoCodeForm}
            className={styles.addCouponForm}
            onSubmit={(e) => {
              e.preventDefault();
              addOrderCoupon({ coupon: couponText });
              this.focusOnError = true;
            }}
          >
            <Text typography="p2-s" className={styles.label}>
              {t('cart_promo_title')}
            </Text>
            <div className={styles.horizontal}>
              <TextField
                inputRef={(e) => (this.inputRef = e)}
                className={styles.couponInput}
                value={this.getTextFieldValue()}
                onChange={this.handleCouponCodeChange}
                data-hook={dataHooks.cartPromoCodeInput}
                placeholder={t('cart_promo_placeholder')}
                aria-label={t('cart_promo_placeholder')}
                error={!!error}
                errorMessage={t(error || '')}
                autoFocus
                onBlur={this.handleBlur}
                errorTooltipPlacement={errorTooltipPlacement}
              />
              <Button type="submit" upgrade data-hook={dataHooks.cartSubmitPromoCode}>
                {t('cart_promo_cta')}
              </Button>
            </div>
          </form>
        ) : (
          <div className={styles.buttonWrapper}>
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={() => this.handleClickOnAddCoupon()}
              data-hook={dataHooks.cartAddPromoCode}
              className={styles.fullWidthButton}
              disabled={disabled}
            >
              <Text typography="p2-m-colorless">{t('cart_button_promo')}</Text>
            </TextButton>
          </div>
        )}
      </div>
    );
  }
}

export default translate()(CartCoupon);
